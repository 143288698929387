import { createApp } from "vue";
import App from "./App.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronDown,
  faTimes,
  faMobileAlt,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import VueGtag from "vue-gtag-next";

library.add(faChevronDown, faInstagram, faTimes, faMobileAlt, faPaperPlane);

createApp(App)
  .use(VueGtag, {
    property: {
      id: "G-JTY33YQ8K8",
    },
  })
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
