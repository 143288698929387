<template>
  <div @click="closeMenuIfOpen" id="landing-page">
    <div
      v-if="isMobile"
      :class="{ menuOpen: isMenuOpen, menuBoxShadow: isMenuOpen }"
      id="navigation-menu"
    >
      <font-awesome-icon
        id="close-menu"
        :icon="['fas', 'times']"
        @click="toggleMenu"
      />
      <img
        id="sidebar-menu"
        src="../../assets/perfect-pet-logo-white-letters.svg"
        alt=""
      />
      <p @click="scrollTo('services', 'start')" class="navigation-menu-item">
        Υπηρεσίες
      </p>
      <!-- <p class="navigation-menu-item">Φωτογραφίες</p> -->
      <p @click="scrollTo('contact-us', 'start')" class="navigation-menu-item">
        Επικοινωνία
      </p>
      <div id="menu-contact-details">
        <div id="find-us-here-container">
          <p id="find-us-here">Βρες μας εδώ:</p>
        </div>
        <div>
          <a
            id="phone-container"
            style="text-decoration: none !important"
            href="tel:+306942241250"
            ><font-awesome-icon
              id="mobile-icon"
              :icon="['fas', 'mobile-alt']"
            />
            <p id="mobile-phone">694 224 1250</p></a
          >
        </div>
        <div id="instagram-container">
          <!-- <p id="follow-us"> -->
            <a
              target="_blank"
              href="https://www.instagram.com/eva_ntzachristou/"
              ><span style="margin-right: 5px;"><font-awesome-icon id="instagram" :icon="['fab', 'instagram']" /></span>
              @eva_ntzachristou</a
            >
          <!-- </p> -->
        </div>
        <div>
          <a
            id="email-container"
            style="text-decoration: none !important"
            target="_blank"
            href="mailto:perfectpetchania@gmail.com"
          >
            <font-awesome-icon
              id="menu-mail-icon"
              :icon="['fas', 'paper-plane']"
            />
            <h4 id="menu-mail-address">perfectpetchania<br />@gmail.com</h4>
          </a>
        </div>
      </div>
    </div>
    <div id="main-page">
      <div
        :class="{
          visibleNavbar: !isOnTop,
          invisibleNavbar: isOnTop && !isInitLoad,
        }"
        id="navbar"
      >
        <div id="logo">
          <img
            v-if="isOnTop && windowWidth < 700"
            :class="{ 'smaller-mobile-navbar-logo': windowWidth < 700 }"
            src="../../assets/perfect-pet-logo-white-letters.svg"
            alt=""
          />
          <img
            v-else
            :class="{ 'smaller-mobile-navbar-logo': windowWidth < 700 }"
            src="../../assets/perfect-pet-logo.svg"
            alt=""
          />
        </div>
        <div v-if="!isMobile" id="navigation">
          <h4
            @click="scrollTo('services', 'start')"
            :class="{
              'active-nav-element': isServicesInViewport,
              darkFont: !isOnTop,
              'active-dark-font': isServicesInViewport && !isOnTop,
            }"
          >
            Υπηρεσίες
          </h4>
          <h4
            @click="scrollTo('contact-us', 'start')"
            :class="{
              'active-nav-element': isContactUsInViewport,
              darkFont: !isOnTop,
              'active-dark-font': isContactUsInViewport && !isOnTop,
            }"
          >
            Επικοινωνία
          </h4>
        </div>
        <div v-else id="mobile-navigation">
          <img
            v-if="isOnTop"
            id="menu-button"
            @click="toggleMenu"
            src="../../assets/pp-menu-white.svg"
            alt=""
          />
          <img
            v-else
            id="menu-button"
            @click="toggleMenu"
            src="../../assets/pp-menu-purple.svg"
            alt=""
          />
        </div>
      </div>
      <div id="copyright-container">
        <h1 id="main-title">
          Ζήσε χαρούμενα κι αρμονικά, παρέα με το σκυλάκι σου
        </h1>
        <h3 id="subtitle">
          Κατ' οίκον εκπαίδευση σκύλων και<br />ζώων συντροφιάς
        </h3>
        <Button @click="scrollTo('contact-us', 'start')" id="landing-button"
          >Κλείσε ραντεβού!</Button
        >
      </div>
      <img
        v-if="windowWidth > 700 && windowWidth >= windowHeight"
        id="blue-wave"
        src="../../assets/wave.svg"
        alt=""
      />
      <img
        v-else
        id="blue-wave"
        src="../../assets/mobile-blue-wave.png"
        alt=""
      />
      <div id="landing-blob-container">
        <img id="landing-blob" src="../../assets/landing-blob.png" alt="" />
        <img id="center-dog" src="../../assets/landing-dog.png" alt="" />
        <img id="paw-dog" src="../../assets/paw-dog-sqare.jpg" alt="" />
        <div id="running-dog-green-circle"></div>
        <img id="running-dog" src="../../assets/running-dog.jpg" alt="" />
        <div id="lara-white-circle"></div>
        <img id="lara" src="../../assets/lara.jpg" alt="" />
      </div>
    </div>

    <div id="services">
      <img
        id="service-collar1"
        class="dog-collar"
        src="../../assets/dog-collar.svg"
        alt=""
      />
      <img
        id="service-collar2"
        class="dog-collar"
        src="../../assets/dog-collar.svg"
        alt=""
      />
      <img
        id="service-collar3"
        class="dog-collar"
        src="../../assets/dog-collar.svg"
        alt=""
      />
      <img
        id="service-bone1"
        class="dog-bone"
        src="../../assets/dog-bone.svg"
        alt=""
      />
      <img
        id="service-bone2"
        class="dog-bone"
        src="../../assets/dog-bone.svg"
        alt=""
      />
      <img
        v-if="isMobile && hasMobileHeight"
        id="service-bone3"
        class="dog-bone"
        src="../../assets/dog-bone.svg"
        alt=""
      />
      <img
        id="service-bowl1"
        class="cat-bowl"
        src="../../assets/cat-bowl.svg"
        alt=""
      />
      <img
        id="service-bowl2"
        class="cat-bowl"
        src="../../assets/cat-bowl.svg"
        alt=""
      />
      <div id="service-background-bubble1" class="bubble pink"></div>
      <h1 id="services-section-title">Υπηρεσίες</h1>
      <ul id="services-container">
        <li>
          <div class="service">
            <div class="service-bubble" id="service-bubble1"></div>
            <div class="service-title" id="service-title1">
              <p>Βασική εκπαίδευση</p>
            </div>
            <div class="service-body">
              <p>
                <span>
                  Περιλαμβάνει τις εντολές κάτσε, ξάπλωσε, άστο, έλα, μείνε,
                  πήγαινε στη θέση σου.
                </span>
              </p>
            </div>
          </div>
        </li>
        <li>
          <div class="service">
            <div class="service-bubble" id="service-bubble2"></div>
            <div class="service-title" id="service-title2">
              <p>Προεκπαίδευση κουταβιού</p>
            </div>
            <div class="service-body">
              <p id="service-text2">
                Γίνεται από την πρώτη στιγμή που έρχεται το κουτάβι στο σπίτι,
                και περιλαμβάνει εκπαίδευση τουαλέτας, απευαισθητοποίηση σε
                ποικίλα ερεθίσματα, σωστή χρήση πάρκου και βασικές εντολές όπως
                το κάτσε, έλα και άστο.
              </p>
            </div>
          </div>
        </li>
        <li>
          <div class="service">
            <div class="service-bubble" id="service-bubble3"></div>
            <div class="service-title" id="service-title3">
              <p>Προχωρημένη εκπαίδευση</p>
            </div>
            <div class="service-body">
              <p>
                Ο σκύλος μαθαίνει να μας υπακούει χωρίς λουρί, σε πληθώρα
                ερεθισμάτων, με χειρονομίες ή λεκτικές εντολές. Προϋπόθεση έχει
                την ολοκλήρωση της βασικής εκπαίδευσης.
              </p>
            </div>
          </div>
        </li>
        <li>
          <div class="service">
            <div class="service-bubble" id="service-bubble4"></div>
            <div class="service-title" id="service-title1">
              <p>Tricks</p>
            </div>
            <div class="service-body">
              <p>
                Κόλπα που θέλετε να μάθετε στο κατοικίδιό σας όπως στροφή,
                τούμπα, φέρε μου ένα αντικείμενο, μίλα, κλείσε το ντουλάπι και
                άλλα.
              </p>
            </div>
          </div>
        </li>
        <li>
          <div class="service">
            <div class="service-bubble" id="service-bubble5"></div>
            <div
              class="service-title big-title-padding-top"
              id="service-title2"
            >
              <p>Αντιμετώπιση προβληματικής συμπεριφοράς</p>
            </div>
            <div class="service-body">
              <p>
                Συμπεριφορές που θα θέλατε να αλλάξουν, όπως υπερβολικό
                γαύγισμα, επιθετικότητα σε άλλους σκύλους, φοβίες, προβλήματα
                αποχωρισμού κλπ.
              </p>
            </div>
          </div>
        </li>
        <li>
          <div class="service">
            <div class="service-bubble" id="service-bubble6"></div>
            <div
              class="service-title big-title-padding-top"
              id="service-title2"
            >
              <p>Συμβουλευτικές συνεδρίες και από Skype</p>
            </div>
            <div class="service-body">
              <p>
                Κλείστε ραντεβού και ολοκληρώστε την εκπαίδευση του σκύλου σας
                διαδικτυακά με τη βοήθεια επαγγελματία εκπαιδευτή.
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- <div id="first-text">
      <img
        id="first-collar"
        class="dog-collar"
        src="../../assets/dog-collar.svg"
        alt=""
      />
      <img
        id="first-bone"
        class="dog-bone"
        src="../../assets/dog-bone.svg"
        alt=""
      />
      <img
        id="first-bowl"
        class="cat-bowl"
        src="../../assets/cat-bowl.svg"
        alt=""
      />
      <div id="first-bubble1" class="bubble purple-blue"></div>
      <div id="first-bubble2" class="bubble blue"></div>
      <div id="first-bubble3" class="bubble pink"></div>

      <div class="image-and-text" id="first-image-and-text">
        <div id="blue-image-container">
          <img id="blue-circle" src="../../assets/blue-circle.png" alt="" />
          <img id="blue-dogos" src="../../assets/blue-dogos2.jpg" alt="" />
        </div>
        <div class="text-container" id="blue-text-container">
          <h4 class="section-title">Είμαι μπούζας;</h4>
          <h4 id="first-section-paragraph" class="section-paragraph">
            Ναι, η αλήθεια είναι πως είμαι μπούζας αλλά αυτό δεν επηρεάζει την
            εκπαίδευση που θα κάνουμε στο σκυλάκι σας, δεν θα γίνει και αυτό
            μπούζας αν δεν είναι ήδη. Αν και για να κάνει ένα σκυλάκι τούμπα,
            πρέπει να έχει ήδη μια προδιάθεση για μπουζίαση
          </h4>
        </div>
      </div>
    </div>

    <div id="second-text">
      <img
        id="second-collar"
        class="dog-collar"
        src="../../assets/dog-collar.svg"
        alt=""
      />
      <img
        id="second-bone"
        class="dog-bone"
        src="../../assets/dog-bone.svg"
        alt=""
      />
      <img
        id="second-bowl"
        class="cat-bowl"
        src="../../assets/cat-bowl.svg"
        alt=""
      />
      <div id="second-bubble1" class="bubble purple-blue"></div>
      <div id="second-bubble2" class="bubble blue"></div>
      <div id="second-bubble3" class="bubble blue"></div>
      <div id="second-bubble4" class="bubble light-pink"></div>

      <div class="image-and-text" id="second-image-and-text">
        <div class="text-container" id="pink-text-container">
          <h4 class="section-title">Είμαι μπούζας;</h4>
          <h4 class="section-paragraph">
            Ναι, η αλήθεια είναι πως είμαι μπούζας αλλά αυτό δεν επηρεάζει την
            εκπαίδευση που θα κάνουμε στο σκυλάκι σας, δεν θα γίνει και αυτό
            μπούζας αν δεν είναι ήδη. Αν και για να κάνει ένα σκυλάκι τούμπα,
            πρέπει να έχει ήδη μια προδιάθεση για μπουζίαση
          </h4>
        </div>
        <div id="pink-image-container">
          <img id="pink-circle" src="../../assets/pink-circle.png" alt="" />
          <img
            id="pink-dogo"
            src="../../assets/pink-dogo-removebg-preview.png"
            alt=""
          />
        </div>
      </div>
    </div> -->

    <!-- <div id="faq">
      <img
        id="faq-collar"
        class="dog-collar"
        src="../../assets/dog-collar.svg"
        alt=""
      />
      <img
        id="faq-bone"
        class="dog-bone"
        src="../../assets/dog-bone.svg"
        alt=""
      />
      <img
        id="faq-bowl"
        class="cat-bowl"
        src="../../assets/cat-bowl.svg"
        alt=""
      />
      <div id="faq-bubble1" class="bubble purple"></div>
      <div id="faq-bubble2" class="bubble purple"></div>
      <div id="faq-bubble3" class="bubble blue"></div>
      <div id="faq-bubble4" class="bubble pink"></div>
      <div id="faq-bubble5" class="bubble purple-blue"></div>

      <h1 id="faq-section-title">Συχνές ερωτήσεις</h1>
      <frequently-asked-questions></frequently-asked-questions>
    </div> -->

    <div id="contact-us">
      <img
        id="contact-us-bone1"
        class="dog-bone"
        src="../../assets/dog-bone.svg"
        alt=""
      />
      <img
        id="contact-us-bowl1"
        class="cat-bowl"
        src="../../assets/cat-bowl.svg"
        alt=""
      />
      <img
        id="contact-us-collar1"
        class="dog-collar"
        src="../../assets/dog-collar.svg"
        alt=""
      />
      <div id="contact-us-text">
        <h4
          data-aos="fade-left"
          data-aos-easing="ease-in-out"
          data-aos-duration="650"
          data-aos-delay="200"
          :data-aos-once="true"
        >
          Θες να εκπαιδεύσεις το ζωάκι σου;
          <br />Στείλε μας μήνυμα
        </h4>
      </div>
      <div id="contact-us-form">
        <div
          data-aos="fade-right"
          data-aos-easing="ease-in-out"
          data-aos-duration="650"
          data-aos-delay="200"
          :data-aos-once="true"
          id="form-container"
        >
          <div class="input-container">
            <h4>Όνομα</h4>
            <input
              @focus="onFocus"
              @blur="onBlur"
              v-model="name"
              type="text"
              name="name"
              id="name"
            />
          </div>
          <div class="input-container">
            <h4>Τηλέφωνο</h4>
            <input
              @focus="onFocus"
              @blur="onBlur"
              v-model="phone"
              type="tel"
              name="phone"
              id="phone-input"
            />
          </div>
          <div class="input-container">
            <h4>Email</h4>
            <input
              @focus="onFocus"
              @blur="onBlur"
              v-model="email"
              type="email"
              name="email"
              id="email"
            />
          </div>
          <div id="text-area-container" class="input-container">
            <h4>Μήνυμα</h4>
            <textarea
              @focus="onFocus"
              @blur="onBlur"
              v-model="message"
              name="message"
              id="message"
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <p v-if="formSuccess == false" class="error">{{ errorMessage }}</p>
          <p v-if="formSuccess" class="success">{{ successfullMessage }}</p>
          <Button :loading="buttonLoading" @click="submit" id="send-button"
            >Αποστολή</Button
          >

          <div :class="{ shape: true, shape1: true, formShape1: true }"></div>
          <div :class="{ shape: true, shape2: true, formShape2: true }"></div>
          <div :class="{ shape: true, shape3: true, formShape3: true }"></div>
          <div :class="{ shape: true, shape6: true, formShape6: true }"></div>
          <div :class="{ shape: true, shape4: true, formShape4: true }"></div>
          <div :class="{ shape: true, shape5: true, formShape5: true }"></div>
        </div>
      </div>
    </div>
    <div @mousemove="updateCoordinatesFooter" id="footer">
      <div id="footer-logo-container">
        <h4 id="footer-logo">
          <img src="../../assets/perfect-pet-logo-white-letters.svg" alt="" />
        </h4>
        <h4 id="footer-description">
          Στα Perfect Pet, στόχος μας είναι να κάνουμε εύκολη την
          επικοινωνίας μεταξύ ανρθώπου και κατοικιδίου.
          <br />
          Το καταφέρνουμε αυτό με κατ' οίκον εκπαίδευση, με επιστημονικές
          μεθόδους που βοηθούν το ζωάκι να είναι άνετα και χαρούμενο όσο
          μαθαίνει
        </h4>
      </div>
      <div id="footer-links">
        <!-- <div id="links1">
          <h4 id="useful-links">Χρήσιμοι σύνδεσμοι</h4>
          <div id="links">
            <div id="link-column1">
              <h4>Όροι χρήσης or sum, idk</h4>
              <h4 @click="goToInfo('terms-of-service')">Όροι χρήσης</h4>
              <h4 @click="goToInfo('contact-us')">Επικοινωνία</h4>
              <h4 @click="goToInfo('privacy-policy')">Πολιτική απορρήτου</h4>
            </div>
            <div id="link-column2">
              <h4 @click="goToInfo('rating-policy')">Πολιτική αξιολογήσεων</h4>
              <h4 @click="goToInfo('cookies-policy')">Πολιτική cookies</h4>
            </div>
          </div>
        </div> -->
        <div id="links2">
          <h4 id="useful-links">Πληροφορίες</h4>
          <div id="links">
            <div id="link-column3">
              <div id="footer-instagram">
                <a
                  id="instagram-hyperlink"
                  style="text-decoration: none!; display: flex"
                  target="_blank"
                  href="https://www.instagram.com/eva_ntzachristou/"
                >
                  <font-awesome-icon
                    class="footer-icon"
                    id="instagram-icon"
                    :icon="['fab', 'instagram']"
                  />
                  <h4 id="footer-instagram-text">@eva_ntzachristou</h4>
                </a>
              </div>
              <div id="phone">
                <a
                  id="phone-hyperlink"
                  style="text-decoration: none!; display: flex"
                  href="tel:+306942241250"
                >
                  <font-awesome-icon
                    id="phone-icon"
                    class="footer-icon"
                    icon="mobile-alt"
                  />
                  <h4 id="footer-phone-number">694 224 1250</h4>
                </a>
              </div>
              <div id="contact-email">
                <a
                  id="mail-hyperlink"
                  style="text-decoration: none!; display: flex"
                  target="_blank"
                  href="mailto:perfectpetchania@gmail.com"
                >
                  <font-awesome-icon
                    class="footer-icon"
                    id="mail-icon"
                    :icon="['fas', 'paper-plane']"
                  />
                  <h4 id="footer-email-text">
                    perfectpetchania<br />@gmail.com
                  </h4>
                </a>
              </div>
              <!-- <div id="street">
                <font-awesome-icon class="icon" :icon="['far', 'map']" />
                <h4>
                  Αγίου Δημητρίου και Παπαδιαμάντη <br />γωνία, Πετρ-αυτόνα
                </h4>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        :style="{
          transform:
            'translate(' +
            this.footerX +
            'px,' +
            this.footerY +
            'px) !important',
        }"
        class="footer-shape shape-1"
      ></div>
      <div
        :style="{
          transform:
            'translate(' +
            this.footerX +
            'px,' +
            this.footerY +
            'px) !important',
        }"
        class="footer-shape shape-2"
      ></div>
      <div
        :style="{
          transform:
            'translate(' +
            this.footerX +
            'px,' +
            this.footerY +
            'px) !important',
        }"
        class="footer-shape shape-3"
      ></div>
      <div
        :style="{
          transform:
            'translate(' +
            this.footerX +
            'px,' +
            this.footerY +
            'px) !important',
        }"
        class="footer-shape shape-4"
      ></div>
      <div
        :style="{
          transform:
            'translate(' +
            this.footerX +
            'px,' +
            this.footerY +
            'px) !important',
        }"
        class="footer-shape shape-5"
      ></div>
      <div
        :style="{
          transform:
            'translate(' +
            this.footerX +
            'px,' +
            this.footerY +
            'px) !important',
        }"
        class="footer-shape shape-6"
      ></div>
      <div
        :style="{
          transform:
            'translate(' +
            this.footerX +
            'px,' +
            this.footerY +
            'px) !important',
        }"
        class="footer-shape shape-7"
      ></div> -->
    </div>
  </div>
</template>

<script>
import Button from "../Button.vue";
import "../../screenSize";
import screenSize from "../../screenSize";
// import FrequentlyAskedQuestions from "../frequentlyAskedQuestions.vue";
import { useWindowSize } from "vue-window-size";
// import ClickOutside from "vue-click-outside";
import { init } from "@emailjs/browser";
import emailjs from "emailjs-com";

export default {
  components: {
    Button,
    // FrequentlyAskedQuestions,
  },
  setup() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  // directives: {
  //   ClickOutside,
  // },
  mounted() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.onScroll);
    init("user_4jbab7cAGa9EnUSDidIhq");
    // this.isEllipsisActive();
    window.addEventListener("scroll", this.isInViewport);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("scroll", this.isInViewport);
  },
  computed: {
    isMobile() {
      return screenSize().width.value < 799;
    },

    hasMobileHeight() {
      return screenSize().height.value <= 950;
    },

    name: {
      set(newName) {
        this.clientName = newName;
      },

      get() {
        return this.clientName;
      },
    },

    phone: {
      set(newPhone) {
        this.clientPhone = newPhone;
      },

      get() {
        return this.clientPhone;
      },
    },
    email: {
      set(newEmail) {
        this.clientEmail = newEmail;
      },

      get() {
        return this.clientEmail;
      },
    },

    message: {
      set(newMessage) {
        this.clientMessage = newMessage;
      },

      get() {
        return this.clientMessage;
      },
    },
  },
  data() {
    return {
      isOnTop: true,
      isInitLoad: true,
      isMenuOpen: false,
      clientName: "",
      clientPhone: "",
      clientEmail: "",
      clientMessage: "",
      formSuccess: false,
      errorMessage: "",
      buttonLoading: false,
      successfullMessage: "",
      errorInSendMessage: "",
      isServicesInViewport: true,
      isContactUsInViewport: false,
    };
  },
  methods: {
    onScroll: function () {
      if (this.isInitLoad) {
        this.isInitLoad = false;
      }

      this.isOnTop = window.top.scrollY == 0;
    },

    // isEllipsisActive: function () {
    //   let e = document.getElementById("service-text2");
    //   return e.offsetWidth < e.scrollWidth;
    // },

    toggleMenu: function () {
      this.isMenuOpen = !this.isMenuOpen;
    },

    closeMenu: function () {
      this.isMenuOpen = false;
    },

    closeMenuIfOpen: function (event) {
      console.log(event.target.classList);
      if (
        event.target.id != "menu-button" &&
        !event.target.classList.contains("menuOpen") &&
        !event.target.classList.contains("navigation-menu-header") &&
        !event.target.classList.contains("navigation-menu-item")
      ) {
        if (this.isMenuOpen) {
          this.isMenuOpen = false;
        }
      }
    },

    scrollTo: function (elementId, block) {
      let element = document.getElementById(elementId);
      element.scrollIntoView({
        behavior: "smooth",
        block: block,
        inline: "nearest",
      });

      if (screenSize().width.value <= 800) {
        this.isMenuOpen = false;
      }
    },

    submit: async function () {
      this.buttonLoading = true;
      console.log("yolo");
      if (this.hasEmptyInputs()) {
        this.formSuccess = false;
        this.errorMessage = "Όλα τα πεδία είναι υποχρεωτικά";
        this.buttonLoading = false;
        return;
      }

      if (this.hasInvalidEmail()) {
        this.formSuccess = false;
        this.errorMessage =
          "Το email πρέπει να είναι του τύπου someone@example.com";
        this.buttonLoading = false;
        return;
      }

      var templateParams = {
        from_name: this.clientName,
        from_phone: this.clientPhone,
        from_email: this.clientEmail,
        message: this.clientMessage,
      };

      emailjs
        .send(
          "personal_service",
          "perfect_pet",
          templateParams,
          "user_4jbab7cAGa9EnUSDidIhq"
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            if (response.status == 200) {
              this.formSuccess = true;
              this.successfullMessage = "Το μήνυμα στάλθηκε με επιτυχία";
            }
          },
          (error) => {
            console.log("FAILED...", error);
            this.errorInSendMessage = true;
            this.formSuccess = false;
            this.sendMessageError = "Κάτι πήγε λάθος";
            this.errorMessage = "Κάτι πήγε λάθος";
          }
        );

      // let messageData = {
      //   name: this.clientName,
      //   phone: this.clientPhone,
      //   email: this.clientEmail,
      //   message: this.clientMessage,
      // };

      // let result = await MessageApi.postMessage(messageData);

      // let result = {
      //   success: true,
      //   message: {
      //     GRE: "κθρςα",
      //   },
      // };

      this.buttonLoading = false;
    },

    hasEmptyInputs: function () {
      let emptyEmail = this.clientEmail.replace(/\s+/g, "") === "";
      let emptyPhone = this.clientPhone.replace(/\s+/g, "") === "";
      let emptyName = this.clientName.replace(/\s+/g, "") === "";
      let emptyMessage = this.clientMessage.replace(/\s+/g, "") === "";

      return emptyEmail || emptyPhone || emptyName || emptyMessage;
    },

    hasInvalidEmail: function () {
      let emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let isValidEmail = emailRegex.test(
        String(this.clientEmail).toLowerCase()
      );

      return !isValidEmail;
    },

    isInViewport: function () {
      let servicesEl = document.getElementById("services");
      var servicesRect = servicesEl.getBoundingClientRect();

      let isServicesOnScreen =
        servicesRect.bottom > 0 + screenSize().height.value * 0.05;
      this.isServicesInViewport = isServicesOnScreen;
      // let isHardPlacesOnScreen =
      //   hardPlacesRect.bottom > 0 + this.$screen.height * 0.1;
      // let isRoomServiceOnScreen =
      //   roomServiceRect.bottom > 0 + this.$screen.height * 0.1;
      // let isMultipleServicesOnScreen =
      //   multipleServicesRect.bottom > 0 + this.$screen.height * 0.1;

      // this.isHowInViewport =
      //   isHowOnScreen ||
      //   isHardPlacesOnScreen ||
      //   isRoomServiceOnScreen ||
      //   isMultipleServicesOnScreen;

      // let whyEl = document.getElementById("why-fluter");
      // var whyRect = whyEl.getBoundingClientRect();

      // this.isWhyInViewport =
      //   whyRect.top <= 0 + this.$screen.height * 0.1 &&
      //   whyRect.bottom > 0 + this.$screen.height * 0.1;

      let contactEl = document.getElementById("contact-us");
      var contactRect = contactEl.getBoundingClientRect();

      this.isContactUsInViewport =
        contactRect.top <= 0 + screenSize().height.value * 0.3 &&
        contactRect.bottom > 0 + screenSize().height.value * 0.15;

      // let carouselContainerEl = document.getElementById("carousel-container");
      // var carouselContainerRect = carouselContainerEl.getBoundingClientRect();

      // this.isCarouselInViewport =
      //   carouselContainerRect.top <= 0 + this.$screen.height * 0.5 &&
      //   carouselContainerRect.bottom > 0 + this.$screen.height * 0.5;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Literata:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

a {
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

a:active {
  text-decoration: none;
  color: inherit;
}

#landing-page {
  display: flex;
  flex-direction: column;
}

#main-page {
  height: 100vh;
}
/* @media screen and (max-width: 1024px) and (min-height: 1100px) {
  #main-page {
    height: unset;
    display: flex;
    flex-direction: column;
  }
} */
@media screen and (max-width: 700px) {
  #main-page {
    height: unset;
    display: flex;
    flex-direction: column;
  }
}

#blue-wave {
  position: absolute;
  top: 0;
  right: 0;
  width: 75vw;
}
@media screen and (max-width: 1280px) and (max-height: 600px) {
  #blue-wave {
    width: 67vw;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #blue-wave {
    width: 120vw;
    transform: translateY(-15vw);
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #blue-wave {
    width: 120vw;
    transform: translateY(-7.5vw);
  }
}
@media screen and (max-width: 720px) and (max-height: 520px) {
  #blue-wave {
    width: 75vw;
  }
}
@media screen and (max-width: 700px) {
  #blue-wave {
    width: 120vw;
  }
}

#copyright-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 55%;
  height: 100vh;
  z-index: 2;
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #copyright-container {
    position: inherit;
    width: 100%;
    height: unset;
    justify-content: flex-start;
    margin-bottom: 500px;
    /* margin-right: 200px; */
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #copyright-container {
    position: inherit;
    width: 100%;
    height: unset;
    justify-content: flex-start;
    margin-bottom: 500px;
    /* margin-right: 200px; */
  }
}
@media screen and (max-width: 700px) {
  #copyright-container {
    position: inherit;
    width: 100%;
    height: unset;
    justify-content: flex-start;
    margin-bottom: 50px;
    margin-right: 200px;
  }
}

#main-title {
  width: 45vw;
  margin: 120px 3vw 2vh 0;
  color: #333;
  letter-spacing: 1px;
  line-height: 5.7rem;
  font-family: "Source Serif Pro", serif;
  font-size: 70px;
  font-weight: 700;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 1921px) {
  #main-title {
    width: 45vw;
    margin-left: 10vw;
    margin-bottom: 1vh;
    font-size: 72px;
    line-height: 6rem;
  }
}
@media screen and (max-width: 1610px) {
  #main-title {
    width: 45vw;
    margin-left: 10vw;
    font-size: 58px;
    line-height: 4.8rem;
  }
}
@media screen and (max-width: 1536px) {
  #main-title {
    width: 45vw;
    margin-left: 10vw;
    /* margin-left: 4vw; */
    font-size: 55px;
    line-height: 4.5rem;
  }
}
@media screen and (max-width: 1440px) and (max-height: 900px) {
  #main-title {
    width: 45vw;
    margin-left: 10vw;
    /* margin-left: 4vw; */
    font-size: 52px;
    line-height: 4.2rem;
  }
}
@media screen and (max-width: 1400px) and (min-height: 899px) {
  #main-title {
    width: 45vw;
    margin-left: 10vw;
    /* margin-left: 4vw; */
    margin-top: 90px;
    font-size: 55px;
    line-height: 4.2rem;
    margin-bottom: 1.5vh;
  }
}
@media screen and (max-width: 1366px) {
  #main-title {
    width: 45vw;
    font-size: 50px;
    margin-left: 10vw;
    line-height: 4rem;
  }
}
@media screen and (max-width: 1367px) and (min-height: 1000px) {
  #main-title {
    width: 45vw;
    font-size: 50px;
    margin-left: 10vw;
    line-height: 4rem;
    margin-top: 10vh;
  }
}
@media screen and (max-width: 1280px) and (min-height: 799px) {
  #main-title {
    width: 45vw;
    margin-left: 8vw;
    font-size: 55px;
    line-height: 4.5rem;
    margin-top: -20px;
  }
}
@media screen and (max-width: 1280px) and (max-height: 600px) {
  #main-title {
    width: 41vw;
    font-size: 50px;
    line-height: 4.33rem;
    margin-left: 6.5vw;
  }
}
@media screen and (max-width: 1152px) {
  #main-title {
    width: 45vw;
    margin-left: 10vw;
    line-height: 3.75rem;
    font-size: 45px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 1115px) and (min-height: 800px) {
  #main-title {
    width: 40vw;
    margin-left: 5vw;
    line-height: 3.75rem;
    font-size: 45px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 1024px) {
  #main-title {
    width: 45vw;
    margin-left: 10vw;
    font-size: 42px;
    line-height: 3.5rem;
  }
}
@media screen and (max-width: 720px) and (max-height: 520px) {
  #main-title {
    width: 41vw;
    font-size: 45px;
    margin-left: 0;
    line-height: 3.5rem;
  }
}
/* RESPONSIVE VALUES ABOUT */
/* TABLETS */
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #main-title {
    width: 100%;
    font-size: 85px;
    margin-left: 10vw;
    line-height: 7.5rem;
    margin-top: max(210px, 25vh);
    padding-right: 5vw;
    word-wrap: break-word;
  }
}
/* @media screen and (max-width: 835px) and (min-height: 940px) {
  #main-title {
    width: 100%;
    font-size: 75px;
    margin-left: 10vw;
    line-height: 7.5rem;
    margin-top: max(210px, 25vh);
    padding-right: 10px;
    word-wrap: break-word;
  }
} */
@media screen and (max-width: 840px) and (min-height: 1000px) {
  #main-title {
    width: 100%;
    font-size: 57px;
    margin-left: 15vw;
    margin-right: 5vw;
    line-height: 5.5rem;
    margin-top: max(320px, 25vh);
    padding-right: 5vw;
    word-wrap: break-word;
  }
}
@media screen and (max-width: 840px) and (min-height: 1100px) {
  #main-title {
    width: 100%;
    font-size: 68px;
    margin-left: 15vw;
    margin-right: 5vw;
    line-height: 6rem;
    margin-top: max(380px, 25vh);
    padding-right: 5vw;
    word-wrap: break-word;
  }
}

@media screen and (max-width: 700px) {
  #main-title {
    width: 90%;
    font-size: 38px;
    margin-left: 5vw;
    margin-right: 5vw;
    line-height: 3.3rem;
    margin-top: max(210px, 25vh);
    padding-right: 10px;
    word-wrap: break-word;
  }
}
@media screen and (min-width: 539px) and (max-width: 700px) {
  #main-title {
    font-size: 45px;
    margin-left: 10vw;
    line-height: 3.5rem;
    margin-top: max(250px, 30vh);
  }
}
@media screen and (max-width: 450px) and (max-height: 680px) {
  #main-title {
    margin-top: max(160px, 27vh);
  }
}
@media screen and (max-width: 390px) {
  #main-title {
    font-size: 35px;
    line-height: 3.15rem;
  }
}
@media screen and (max-width: 361px) {
  #main-title {
    font-size: 33px;
    line-height: 3rem;
  }
}
@media screen and (max-width: 330px) {
  #main-title {
    font-size: 30px;
    line-height: 2.6rem;
  }
}
@media screen and (max-width: 300px) {
  #main-title {
    font-size: 26px;
    line-height: 2.25rem;
  }
}
@media screen and (min-width: 1680px) and (max-width: 1760px) {
  #main-title {
    width: 45vw;
    margin-left: 9vw;
    margin-bottom: 1vh;
    font-size: 65px;
    line-height: 5rem;
  }
}

#subtitle {
  width: 35vw;
  font-family: "Source Serif Pro", serif;
  font-size: 33px;
  font-style: italic;
  font-weight: 600;
  color: #8888f7;
  /* color: #DABFDE; */
  /* color: #CDB4DB; */
  margin: 0 3vw 4vh 0;
}
@media screen and (max-width: 1921px) {
  #subtitle {
    width: 45vw;
    margin-left: 10vw;
    margin-bottom: 3vh;
    font-size: 38px;
  }
}
@media screen and (max-width: 1610px) {
  #subtitle {
    width: 45vw;
    margin-left: 10vw;
    font-size: 35px;
  }
}
@media screen and (max-width: 1536px) {
  #subtitle {
    width: 41vw;
    margin-left: 6vw;
    /* margin-left: 4vw; */
    font-size: 32px;
  }
}
@media screen and (max-width: 1440px) and (max-height: 900px) {
  #subtitle {
    width: 45vw;
    margin-left: 10vw;
    /* margin-left: 4vw; */
    font-size: 30px;
  }
}
@media screen and (max-width: 1400px) and (min-height: 899px) {
  #subtitle {
    width: 45vw;
    margin-left: 10vw;
    font-size: 32px;
  }
}
@media screen and (max-width: 1366px) {
  #subtitle {
    width: 45vw;
    margin-left: 10vw;
    font-size: 28px;
  }
}
@media screen and (max-width: 1280px) and (min-height: 801px) {
  #subtitle {
    width: 45vw;
    font-size: 33px;
    margin-left: 8vw;
  }
}
@media screen and (max-width: 1280px) and (max-height: 600px) {
  #subtitle {
    width: 41vw;
    font-size: 28px;
    margin-left: 6.5vw;
  }
}
@media screen and (max-width: 1152px) {
  #subtitle {
    width: 45vw;
    margin-left: 10vw;
    font-size: 26px;
  }
}
@media screen and (max-width: 1024px) {
  #subtitle {
    width: 45vw;
    margin-left: 10vw;
    font-size: 25px;
  }
}
@media screen and (max-width: 720px) and (max-height: 520px) {
  #subtitle {
    width: 41vw;
    font-size: 21px;
    margin-left: 0;
  }
}
/* RESPONSIVE VALUES ABOUT */
/* TABLETS */
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #subtitle {
    width: 100%;
    font-size: 45px;
    margin-left: 10vw;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #subtitle {
    width: 100%;
    font-size: 40px;
    margin-left: 10vw;
  }
}
@media screen and (max-width: 840px) and (min-height: 1000px) {
  #subtitle {
    width: 100%;
    font-size: 38px;
    margin-left: 15vw;
  }
}
@media screen and (max-width: 840px) and (min-height: 1120px) {
  #subtitle {
    width: 100%;
    font-size: 45px;
    margin-left: 15vw;
  }
}
@media screen and (max-width: 700px) {
  #subtitle {
    width: 90%;
    font-size: 23px;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}
@media screen and (min-width: 539px) and (max-width: 700px) {
  #subtitle {
    font-size: 28px;
    margin-left: 10vw;
  }
}
@media screen and (max-width: 390px) {
  #subtitle {
    font-size: 21px;
  }
}
@media screen and (max-width: 361px) {
  #subtitle {
    font-size: 21px;
  }
}
@media screen and (max-width: 330px) {
  #subtitle {
    font-size: 19px;
  }
}
@media screen and (max-width: 300px) {
  #subtitle {
    font-size: 16px;
  }
}
@media screen and (min-width: 1680px) and (max-width: 1760px) {
  #subtitle {
    width: 45vw;
    margin-left: 9vw;
    margin-bottom: 2vh;
    font-size: 38px;
  }
}

#landing-button {
  background: #6161d6;
  /* background: #DABFDE; */
  /* background: #CDB4DB; */
  align-self: flex-start;
  margin-left: 8.5vw;
  color: white;
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
}
@media screen and (max-width: 1921px) {
  #landing-button {
    font-size: 20px;
    margin-left: 8.35vw;
  }
}
@media screen and (max-width: 1610px) {
  #landing-button {
    font-size: 16px;
    margin-left: 8.1vw;
  }
}
@media screen and (max-width: 1536px) {
  #landing-button {
    font-size: 16px;
    margin-left: 8.3vw;
    /* margin-left: 9.35vw; */
  }
}
@media screen and (max-width: 1400px) and (min-height: 899px) {
  #landing-button {
    font-size: 16px;
    margin-left: 8.3vw;
  }
}
@media screen and (max-width: 1366px) {
  #landing-button {
    font-size: 14px;
    margin-left: 8.2vw;
  }
}
@media screen and (max-width: 1280px) and (min-height: 801px) {
  #landing-button {
    font-size: 16px;
    margin-left: 7.33vw;
  }
}
@media screen and (max-width: 1280px) and (max-height: 600px) {
  #landing-button {
    font-size: 14px;
    margin-left: 8.4vw;
  }
}
@media screen and (max-width: 1024px) {
  #landing-button {
    font-size: 13px;
  }
}
@media screen and (max-width: 720px) and (max-height: 520px) {
  #landing-button {
    font-size: 12px;
    margin-left: 5.4vw;
  }
}
/* RESPONSIVE VALUES ABOUT */
/* TABLETS */
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #landing-button {
    font-size: 28px;
    margin-left: 4.2vw;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #landing-button {
    font-size: 26px;
    margin-left: 4.2vw;
  }
}
@media screen and (max-width: 840px) and (min-height: 1000px) {
  #landing-button {
    font-size: 23px;
    margin-left: 6.5vw;
  }
}
@media screen and (max-width: 840px) and (min-height: 1120px) {
  #landing-button {
    font-size: 29px;
    margin-left: 6.5vw;
  }
}
/* RESPONSIVE VALUES ABOUT */
/* PHONES */
@media screen and (max-width: 700px) {
  #landing-button {
    font-size: 15px;
    margin-left: 5vw;
  }
}
@media screen and (min-width: 539px) and (max-width: 700px) {
  #landing-button {
    font-size: 15px;
    margin-left: 8vw;
  }
}
@media screen and (max-width: 390px) {
  #landing-button {
    font-size: 14px;
  }
}
@media screen and (max-width: 330px) {
  #landing-button {
    font-size: 12px;
  }
}
@media screen and (max-width: 300px) {
  #landing-button {
    font-size: 12px;
  }
}
@media screen and (min-width: 1680px) and (max-width: 1760px) {
  #landing-button {
    font-size: 18px;
    margin-left: 8vw;
  }
}

#landing-blob-container {
  position: absolute;
  right: 7vw;
  top: 15vh;
  z-index: 2;
}
@media screen and (max-width: 1921px) {
  #landing-blob-container {
    right: 9vw;
    top: 16vh;
  }
}
@media screen and (max-width: 1610px) {
  #landing-blob-container {
    right: 9vw;
    top: 17vh;
  }
}
@media screen and (max-width: 1536px) {
  #landing-blob-container {
    right: 7.5vw;
    /* right: 6.5vw; */
    top: 16.5vh;
  }
}
@media screen and (max-width: 1440px) and (max-height: 900px) {
  #landing-blob-container {
    right: 9vw;
    top: 16.5vh;
  }
}
@media screen and (max-width: 1400px) and (min-height: 899px) {
  #landing-blob-container {
    right: 2vw;
    top: 17.5vh;
  }
}
@media screen and (max-width: 1366px) {
  #landing-blob-container {
    right: 8.5vw;
    top: 18vh;
  }
}
@media screen and (max-width: 1367px) and (min-height: 1000px) {
  #landing-blob-container {
    right: 5vw;
    top: 25vh;
  }
}
@media screen and (max-width: 1280px) and (min-height: 799px) {
  #landing-blob-container {
    right: 2vw;
    top: 15vh;
  }
}
@media screen and (max-width: 1280px) and (min-height: 961px) {
  #landing-blob-container {
    right: 2vw;
  }
}
@media screen and (max-width: 1280px) and (max-height: 600px) {
  #landing-blob-container {
    right: 8vw;
  }
}
@media screen and (max-width: 1152px) {
  #landing-blob-container {
    right: 4vw;
    top: 10vw;
  }
}
@media screen and (max-width: 720px) and (max-height: 520px) {
  #landing-blob-container {
    right: 1vw;
    top: 11.5vh;
  }
}
@media screen and (min-width: 1680px) and (max-width: 1760px) {
  #landing-blob-container {
    right: 5.5vw;
    top: 14.5vh;
  }
}
/* RESPONSIVE VALUES ABOUT */
/* TABLETS */
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #landing-blob-container {
    position: relative;
    /* top: 62.5vh; */
    /* right: -15vw; */
    margin-top: -120vh;
  }
}
@media screen and (max-width: 920px) and (min-height: 1350px) {
  #landing-blob-container {
    position: relative;
    /* top: 62.5vh; */
    /* right: -15vw; */
    margin-top: -50vh;
    margin-left: 20vw;
  }
}
@media screen and (min-width: 650px) and (max-width: 835px) and (min-height: 940px) {
  #landing-blob-container {
    position: relative;
    /* top: 72.5vh;
    right: -15vw; */
    margin-top: -65vh;
    margin-left: 30vw;
  }
}
@media screen and (max-width: 700px) {
  #landing-blob-container {
    position: relative;
    /* top: 14.5vh; */
    margin-top: -15vh;
    margin-left: 20vw;
  }
}
@media screen and (min-width: 539px) and (max-width: 700px) {
  #landing-blob-container {
    position: relative;
    margin-top: -20vh;
    margin-left: 40vw;
  }
}
@media screen and (max-width: 415px) and (max-height: 750px) {
  #landing-blob-container {
    margin-left: 33vw;
  }
}
@media screen and (max-width: 376px) and (max-height: 670px) {
  #landing-blob-container {
    margin-top: -19vh;
    /* margin-left: 25vw; */
  }
}
@media screen and (max-width: 360px) and (max-height: 720px) {
  #landing-blob-container {
    margin-top: -17.5vh;
    margin-left: 25vw;
  }
}
@media screen and (max-width: 360px) and (max-height: 640px) {
  #landing-blob-container {
    margin-top: -19.5vh;
    margin-left: 27.5vw;
  }
}

@media screen and (max-width: 320px) and (max-height: 640px) {
  #landing-blob-container {
    margin-left: 37.5vw;
  }
}

#landing-blob {
  height: 80vh;
}
@media screen and (max-width: 1921px) {
  #landing-blob {
    height: 67.5vh;
  }
}
@media screen and (max-width: 1610px) {
  #landing-blob {
    height: 70vh;
  }
}
@media screen and (max-width: 1536px) {
  #landing-blob {
    height: 75vh;
  }
}
@media screen and (max-width: 1440px) and (max-height: 900px) {
  #landing-blob {
    height: 70vh;
  }
}
@media screen and (max-width: 1401px) and (min-height: 899px) {
  #landing-blob {
    height: 65vh;
  }
}
@media screen and (max-width: 1367px) and (min-height: 1000px) {
  #landing-blob {
    height: 55vh;
  }
}
@media screen and (max-width: 1280px) and (min-height: 801px) {
  #landing-blob {
    height: 60vh;
  }
}
@media screen and (max-width: 1280px) and (min-height: 961px) {
  #landing-blob {
    height: 70vh;
  }
}
@media screen and (max-width: 1280px) and (max-height: 600px) {
  #landing-blob {
    height: 85vh;
  }
}
@media screen and (max-width: 1152px) {
  #landing-blob {
    height: 62.5vh;
  }
}
/* RESPONSIVE VALUES ABOUT */
/* TABLETS */
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #landing-blob {
    height: 62.5vh;
  }
}
@media screen and (max-width: 1024px) and (max-height: 1100px) {
  #landing-blob {
    height: 52.5vh;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #landing-blob {
    height: 62.5vh;
  }
}
@media screen and (max-width: 700px) {
  #landing-blob {
    height: 45vh;
  }
}
@media screen and (min-width: 539px) and (max-width: 700px) {
  #landing-blob {
    height: 55vh;
  }
}
@media screen and (max-width: 360px) and (max-height: 720px) {
  #landing-blob {
    height: 47.5vh;
  }
}
@media screen and (min-width: 1680px) and (max-width: 1760px) {
  #landing-blob {
    height: 72.5vh;
  }
}

#center-dog {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(60%, -50%) scale(-1, 1);
  height: 45%;
}
@media screen and (max-width: 700px) {
  #center-dog {
    transform: translate(75%, -50%) scale(-1, 1);
  }
}
@media screen and (max-width: 360px) and (max-height: 720px) {
  #center-dog {
    transform: translate(80%, -50%) scale(-1, 1);
  }
}

#paw-dog {
  position: absolute;
  left: 10%;
  bottom: 12%;
  height: 23%;
  border-radius: 1000px;
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #paw-dog {
    left: -7%;
    bottom: 32%;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #paw-dog {
    left: -7%;
    bottom: 32%;
  }
}
@media screen and (max-width: 700px) {
  #paw-dog {
    left: -7%;
    bottom: 32%;
  }
}

#running-dog {
  position: absolute;
  left: 7%;
  top: 9%;
  height: 19%;
  border-radius: 1000px;
}
@media screen and (max-width: 1921px) {
  #running-dog {
    left: 12%;
  }
}
@media screen and (max-width: 1610px) {
  #running-dog {
    left: 12%;
  }
}
@media screen and (max-width: 1536px) {
  #running-dog {
    left: 12%;
  }
}
@media screen and (max-width: 1400px) and (min-height: 901px) {
  #running-dog {
    left: 21%;
    top: 5%;
  }
}
@media screen and (max-width: 1280px) and (min-height: 801px) {
  #running-dog {
    left: 23%;
    top: 4%;
  }
}
@media screen and (max-width: 1152px) {
  #running-dog {
    left: 21%;
    top: 4%;
  }
}
@media screen and (max-width: 720px) and (max-height: 520px) {
  #running-dog {
    left: 23%;
    top: 4%;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #running-dog {
    height: 21%;
    left: 37%;
    top: -3.5%;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #running-dog {
    height: 21%;
    left: 37%;
    top: -3.5%;
  }
}
@media screen and (max-width: 700px) {
  #running-dog {
    height: 21%;
    left: 62%;
    top: -5.5%;
  }
}
@media screen and (min-width: 1680px) and (max-width: 1760px) {
  #running-dog {
    left: 16%;
    top: 7%;
  }
}

#running-dog-green-circle {
  position: absolute;
  left: 0%;
  top: 8.5%;
  height: 17%;
  width: 17%;
  background: #d5ffdd;
  border-radius: 1000px;
}
@media screen and (max-width: 1921px) {
  #running-dog-green-circle {
    left: 5%;
  }
}
@media screen and (max-width: 1610px) {
  #running-dog-green-circle {
    left: 5%;
  }
}
@media screen and (max-width: 1536px) {
  #running-dog-green-circle {
    left: 5%;
  }
}
@media screen and (max-width: 1400px) and (min-height: 901px) {
  #running-dog-green-circle {
    left: 14%;
    top: 4.5%;
  }
}
@media screen and (max-width: 1280px) and (min-height: 801px) {
  #running-dog-green-circle {
    left: 16%;
    top: 3.5%;
  }
}
@media screen and (max-width: 1152px) {
  #running-dog-green-circle {
    left: 14%;
    top: 3.5%;
    width: 20%;
  }
}
@media screen and (max-width: 720px) and (max-height: 520px) {
  #running-dog-green-circle {
    left: 17%;
    top: 3.5%;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #running-dog-green-circle {
    height: 19%;
    width: 23%;
    left: 30%;
    top: -3.5%;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #running-dog-green-circle {
    height: 19%;
    width: 23%;
    left: 30%;
    top: -3.5%;
  }
}
@media screen and (max-width: 700px) {
  #running-dog-green-circle {
    height: 19%;
    width: 23%;
    left: 55%;
    top: -5.5%;
  }
}
@media screen and (min-width: 1680px) and (max-width: 1760px) {
  #running-dog-green-circle {
    left: 9%;
    top: 6.5%;
  }
}

#lara {
  position: absolute;
  right: 5%;
  bottom: 1.5%;
  height: 23%;
  /* width: 23%; */
  border-radius: 100%;
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #lara {
    right: 27%;
    bottom: -7%;
    height: 21%;
    width: unset;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #lara {
    right: 27%;
    bottom: -7%;
    height: 21%;
    width: unset;
  }
}
@media screen and (max-width: 700px) {
  #lara {
    right: 23%;
    bottom: -5%;
    height: 21%;
    width: unset;
  }
}

#lara-white-circle {
  position: absolute;
  right: 1%;
  bottom: 9.5%;
  height: 18%;
  width: 18%;
  background: seashell;
  border-radius: 1000px;
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #lara-white-circle {
    width: 20.5%;
    height: 20.5%;
    right: 23%;
    bottom: -3%;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #lara-white-circle {
    width: 20.5%;
    height: 20.5%;
    right: 23%;
    bottom: -3%;
  }
}
@media screen and (max-width: 700px) {
  #lara-white-circle {
    width: 24.5%;
    height: 20.5%;
    right: 16%;
    bottom: -2%;
  }
}

#navbar {
  position: fixed;
  width: 100vw;
  display: flex;
  height: 20vh;
  align-items: center;
  justify-content: space-between;
  padding: 0 7.5vw 0 8.5vw;
  background: transparent;
  box-shadow: none;
  z-index: 3;
}
@media screen and (max-width: 1921px) {
  #navbar {
    padding-left: 8.35vw;
  }
}
@media screen and (max-width: 1680px) and (min-height: 899px) {
  #navbar {
    padding-left: 7.7vw;
  }
}
@media screen and (max-width: 1536px) {
  #navbar {
    padding-left: 8.25vw;
  }
}
@media screen and (max-width: 1450px) and (max-height: 899px) {
  #navbar {
    padding-left: 8.25vw;
  }
}
@media screen and (max-width: 1366px) {
  #navbar {
    padding-left: 8.2vw;
  }
}
@media screen and (max-width: 1280px) and (min-height: 801px) {
  #navbar {
    padding-left: 7.2vw;
  }
}
@media screen and (max-width: 1280px) and (max-height: 600px) {
  #navbar {
    padding-left: 8.4vw;
  }
}
@media screen and (max-width: 720px) and (max-height: 520px) {
  #navbar {
    padding-left: 5.4vw;
  }
}
@media screen and (max-width: 700px) {
  #navbar {
    padding-left: 5vw;
  }
}

.visibleNavbar {
  animation: addBoxShadow 0.4s forwards ease-in-out;
}

@keyframes addBoxShadow {
  from {
    background: transparent;
    box-shadow: none;
    height: 20vh;
  }

  to {
    background: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    height: 10vh;
  }
}

.invisibleNavbar {
  animation: removeBoxShadow 0.4s forwards ease-in-out;
}

@keyframes removeBoxShadow {
  to {
    background: transparent;
    box-shadow: none;
    height: 20vh;
  }

  from {
    background: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    height: 10vh;
  }
}

#navigation {
  display: flex;
  position: relative;
}

#navigation > * {
  margin-left: 7.5vw;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  text-shadow: -1px 1px 3px rgba(0, 0, 0, 0.21);
}
@media screen and (max-width: 1921px) {
  #navigation > * {
    font-size: 22px;
  }
}
@media screen and (max-width: 1536px) {
  #navigation > * {
    font-size: 19px;
  }
}
@media screen and (max-width: 1366px) {
  #navigation > * {
    font-size: 17px;
  }
}
@media screen and (max-width: 1280px) and (min-height: 801px) {
  #navigation > * {
    font-size: 19px;
  }
}
@media screen and (max-width: 720px) and (max-height: 520px) {
  #navigation > * {
    font-size: 14px;
  }
}
/* RESPONSIVE VALUES ABOUT */
/* TABLETS */
@media screen and (max-width: 1025px) and (min-height: 1100px) {
  #navigation > * {
    font-size: 28px;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #navigation > * {
    font-size: 28px;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1760px) {
  #navigation > * {
    font-size: 21px;
  }
}

#logo {
  height: 100%;
  display: flex;
  align-items: center;
}

#logo > img {
  height: 80%;
  /* max-height: 150px; */
  width: auto;
}

.smaller-mobile-navbar-logo {
  height: clamp(50px, 45%, 45%) !important;
}

#sidebar-menu {
  width: 50%;
  height: auto;
  margin-bottom: 8vh;
}

#logo > h4 {
  font-family: "Source Serif Pro", serif;
  letter-spacing: 1px;
  font-size: 33px;
  font-weight: 900;
  color: #333;
  text-shadow: -1px 1px -1px rgba(0, 0, 0, 0.21);
}
@media screen and (max-width: 1921px) {
  #logo > h4 {
    font-size: 38px;
  }
}
@media screen and (max-width: 1530px) {
  #logo > h4 {
    font-size: 38px;
  }
}
@media screen and (max-width: 1024px) {
  #logo > h4 {
    font-size: 30px;
  }
}
@media screen and (max-width: 720px) and (max-height: 520px) {
  #logo > h4 {
    font-size: 25px;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #logo > h4 {
    font-size: 42px;
    color: #fff;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #logo > h4 {
    font-size: 42px;
    color: #fff;
  }
}
@media screen and (max-width: 700px) {
  #logo > h4 {
    font-size: 25px;
    color: #fff;
  }
}
@media screen and (max-width: 300px) {
  #logo > h4 {
    font-size: 20px;
  }
}

#menu-button {
  height: 3vh;
  width: auto;
}
/* @media screen and (max-height: 660px) {
  #menu-button {
    height: 3vh;
    width: auto;
  }
} */

#navigation-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  width: 57%;
  background: #6161d6;
  background: #dabfde;
  background: #cdb4db;
  background: #8888f7;
  position: fixed;
  top: 0;
  right: -57%;
  padding-top: 15vh;
  padding-left: 7%;
  z-index: 999;
  transition: all 0.3s ease-in-out;
}

.menuOpen {
  right: 0 !important;
}

.menuBoxShadow {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.navigation-menu-header {
  font-size: 23px;
  color: #fff;
  font-weight: 800;
  margin: 0;
  padding: 0;
  margin-bottom: 8vh;
}

.navigation-menu-item {
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  margin: 0;
  padding: 0;
  margin-bottom: 4vh;
  text-decoration: underline;
  cursor: pointer;
}

#close-menu {
  position: absolute;
  top: 20px;
  right: 10px;
  color: white;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

#menu-contact-details {
  position: fixed;
  bottom: 20px;
}

#find-us-here-container {
  margin-bottom: 30px;
}

#find-us-here {
  color: #fff;
  font-weight: 700;
  font-size: calc(0.6rem + 2vw);
  /* text-decoration: underline; */
}

#phone-container,
#instagram-container,
#email-container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  color: #6161d6;
  color: #fff;
  /* color: #f4a460; */
  font-weight: 600;
  /* color: lightpink; */
  font-size: calc(0.5rem + 2vw);
  /* background: chocolate; */
  /* box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.24); */
  /* color: #fb9f58; */
  margin-bottom: 20px;
}

#phone-container {
  margin-bottom: 20px;
}

#mobile-phone {
  margin: 0;
  margin-left: 12px;
}

/* #mobile-icon,
#instagram {
} */

#menu-mail-icon {
  transform: translateY(-7px) translateX(-3px);
}

#menu-mail-address {
  margin: 0;
  margin-left: 10px;
}

.dark {
  color: #333 !important;
  transition: all 0.4s;
}

.darkFont {
  color: #333 !important;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.21) !important;
  text-shadow: unset !important;
  font-weight: 900;
}

.active-dark-font {
  color: #fd80b2 !important;
}

.active-nav-element {
  color: #fcabcb !important;
  font-weight: 900 !important;
}

#services {
  font-family: "Source Serif Pro", serif;
  position: relative;
  width: 100%;
  padding-top: 15vh;
  margin-bottom: 25vh;
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #services {
    margin-top: 25vh;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #services {
    margin-top: 30vh;
  }
}
@media screen and (max-width: 720px) {
  #services {
    margin-top: 20vh;
  }
}

#services-section-title {
  width: 100%;
  text-align: center;
  margin-bottom: 10vh;
  font-weight: 900;
  color: #222;
  font-size: 45px;
  text-shadow: 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #services-section-title {
    font-size: 55px;
    /* margin-bottom: 15vh; */
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #services-section-title {
    font-size: 50px;
  }
}
@media screen and (max-width: 720px) {
  #services-section-title {
    font-size: 39px;
    margin-bottom: 15vh;
  }
}

#services-container {
  list-style-type: none;
  margin: auto;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  row-gap: 20vh;
  /* align-content: center; */
  /* align-items: stretch; */
  width: 87.5%;
  /* margin-bottom: 5%; */
}
@media screen and (max-width: 1921px) {
  #services-container {
    row-gap: 33vh;
  }
}
@media screen and (max-width: 1681px) {
  #services-container {
    row-gap: 25vh;
  }
}
@media screen and (max-width: 720px) {
  #services-container {
    width: 100%;
    row-gap: 20vh;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #services-container {
    row-gap: 25vh;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #services-container {
    row-gap: 25vh;
    width: 95vw;
  }
}
@media screen and (max-width: 400px) and (max-height: 670px) {
  #services-container {
    row-gap: 25vh;
  }
}
@media screen and (max-width: 291px) {
  #services-container {
    row-gap: 20vh;
  }
}

li {
  flex: 1 1 33%;
  align-self: stretch;
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  li {
    flex: 1 1 50%;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  li {
    flex: 1 1 50%;
  }
}
@media screen and (max-width: 720px) {
  li {
    flex: 1 1 100%;
  }
}
@media screen and (max-width: 602px) and (min-height: 820px) {
  li {
    flex: 1 1 50%;
  }
}

.service {
  position: relative;
  border-radius: 16px;
  height: 120%;
  min-height: 300px;
  padding-bottom: 20px;
  width: 17vw;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
}
@media screen and (max-width: 1921px) {
  .service {
    height: 150%;
  }
}
@media screen and (max-width: 1681px) {
  .service {
    height: 120%;
    /* width: 20vw; */
  }
}
@media screen and (max-width: 1441px) {
  .service {
    /* height: 120%; */
    width: 18.5vw;
  }
}
@media screen and (max-width: 1401px) {
  .service {
    /* height: 120%; */
    width: 18.5vw;
  }
}
@media screen and (max-width: 1367px) {
  .service {
    /* height: 120%; */
    width: 18vw;
  }
}
@media screen and (max-width: 1153px) {
  .service {
    /* height: 120%; */
    width: 20vw;
  }
}
@media screen and (max-width: 720px) {
  .service {
    width: 67vw;
    height: 120%;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  .service {
    width: 38vw;
    height: 140%;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  .service {
    width: 40vw;
    height: 120%;
  }
}
@media screen and (max-width: 602px) and (min-height: 820px) {
  .service {
    width: 42vw;
    height: 120%;
  }
}
@media screen and (max-width: 415px) and (max-height: 950px) {
  .service {
    width: 67vw;
    height: 125%;
  }
}
@media screen and (max-width: 376px) {
  .service {
    width: 70vw;
  }
}
@media screen and (max-width: 321px) {
  .service {
    height: 115%;
    width: 75vw;
  }
}
@media screen and (max-width: 291px) {
  .service {
    height: 115%;
    width: 80vw;
  }
}

.service-title {
  position: relative;
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* border-top-left-radius: 16px;
  border-top-right-radius: 16px; */
  height: 30%;
  width: 100%;
  font-weight: 800;
  color: #333;
  font-size: 19px;
  text-shadow: 2px 2px 4px rgb(255, 255, 255, 0.24);
  z-index: 2;
}
@media screen and (max-width: 1921px) {
  .service-title {
    font-size: 27px;
  }
}
@media screen and (max-width: 1681px) {
  .service-title {
    font-size: 24px;
    height: 35%;
  }
}
@media screen and (max-width: 1601px) {
  .service-title {
    height: 35%;
    font-size: 22px;
    width: 90%;
    margin-left: 5%;
  }
}
@media screen and (max-width: 1441px) {
  .service-title {
    font-size: 23px;
  }
}
@media screen and (max-width: 1367px) {
  .service-title {
    font-size: 21px;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  .service-title {
    font-size: 30px;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  .service-title {
    font-size: 30px;
  }
}
@media screen and (max-width: 700px) {
  .service-title {
    font-size: 23px;
  }
}
@media screen and (max-width: 415px) and (max-height: 950px) {
  .service-title {
    font-size: 24px;
    /* padding-top: 25px; */
  }
}
@media screen and (max-width: 361px) and (max-height: 641px) {
  .service-title {
    font-size: 23px;
    padding-top: 10px;
  }
}
@media screen and (max-width: 321px) {
  .service-title {
    font-size: 21px;
  }
}

@media screen and (max-width: 415px) and (max-height: 950px) {
  .big-title-padding-top {
    padding-top: 25px;
  }
}
@media screen and (max-width: 361px) and (max-height: 641px) {
  .big-title-padding-top {
    padding-top: 10px;
  }
}

.service-body {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center !important;
  /* background: blue; */
  justify-content: center !important;
  padding: 10px;
  padding-top: 0;
  /* padding-bottom: 30px; */
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 2;
}

.service-body > p {
  margin: 0;
  padding: 0;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  /* overflow: hidden; */
  text-overflow: clip;
  font-weight: 500;
}
@media screen and (max-width: 1921px) {
  .service-body > p {
    font-size: 22px;
  }
}
@media screen and (max-width: 1681px) {
  .service-body > p {
    font-size: 20px;
  }
}
@media screen and (max-width: 1601px) {
  .service-body > p {
    font-size: 19px;
  }
}
@media screen and (max-width: 1601px) and (max-height: 780px) {
  .service-body > p {
    font-size: 18px;
  }
}
@media screen and (max-width: 1441px) {
  .service-body > p {
    font-size: 18px;
  }
}
@media screen and (max-width: 1367px) {
  .service-body > p {
    font-size: 16px;
  }
}
@media screen and (max-width: 1281px) and (min-height: 870px) {
  .service-body > p {
    font-size: 17px;
  }
}
@media screen and (max-width: 1025px) {
  .service-body > p {
    font-size: 15px;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  .service-body > p {
    padding-top: 8px;
    font-size: 25px;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  .service-body > p {
    padding-top: 8px;
    font-size: 24px;
  }
}
@media screen and (max-width: 700px) {
  .service-body > p {
    padding-top: 8px;
    font-size: 17px;
  }
}
@media screen and (max-width: 415px) and (max-height: 950px) {
  .service-body > p {
    /* padding-top: 12px; */
    font-size: 17.5px;
  }
}
@media screen and (max-width: 361px) and (max-height: 641px) {
  .service-body > p {
    font-size: 16px;
  }
}
@media screen and (max-width: 321px) {
  .service-body > p {
    font-size: 15px;
  }
}

.service-bubble {
  width: 15vw;
  height: 15vw;
  position: absolute;
  top: -6vw;
  left: -6vw;
  border-radius: 1000px;
  z-index: 1;
}
@media screen and (max-width: 1681px) {
  .service-bubble {
    width: 14vw;
    height: 14vw;
    top: -6vw;
    left: -6vw;
  }
}
@media screen and (max-width: 1451px) {
  .service-bubble {
    width: 15vw;
    height: 15vw;
    top: -6vw;
    left: -6vw;
  }
}
@media screen and (max-width: 1281px) {
  .service-bubble {
    width: 15.5vw;
    height: 15.5vw;
    top: -6vw;
    left: -6vw;
  }
}
@media screen and (max-width: 1153px) {
  .service-bubble {
    width: 16vw;
    height: 16vw;
    top: -6vw;
    left: -6vw;
  }
}
@media screen and (max-width: 1025px) {
  .service-bubble {
    width: 16.5vw;
    height: 16.6vw;
    top: -6vw;
    left: -6vw;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  .service-bubble {
    width: 26vh;
    height: 26vh;
    top: -10vh;
    left: -10vh;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  .service-bubble {
    width: 31vh;
    height: 31vh;
    top: -13vh;
    left: -13vh;
  }
}
@media screen and (max-width: 720px) {
  .service-bubble {
    width: 26vh;
    height: 26vh;
    top: -10vh;
    left: -10vh;
  }
}
@media screen and (max-width: 425px) and (max-height: 750px) {
  .service-bubble {
    width: 30vh;
    height: 30vh;
    top: -12vh;
    left: -12vh;
  }
}
@media screen and (max-width: 425px) and (max-height: 700px) {
  .service-bubble {
    width: 32vh;
    height: 32vh;
    top: -12vh;
    left: -12vh;
  }
}
@media screen and (max-width: 425px) and (max-height: 650px) {
  .service-bubble {
    width: 35vh;
    height: 35vh;
    top: -14vh;
    left: -15vh;
  }
}
@media screen and (max-width: 425px) and (max-height: 570px) {
  .service-bubble {
    width: 40vh;
    height: 40vh;
    top: -17vh;
    left: -17vh;
  }
}

#service-bubble1 {
  background: #dbecde;
}

#service-bubble2 {
  background: #8888f7;
  background: #8fe381;
}

#service-bubble3 {
  background: #fcabcb;
}

#service-bubble4 {
  background: #fb9f58;
}

#service-bubble5 {
  background: #8fe381;
  background: #8888f7;
}

#service-bubble6 {
  background: #ff6f6f;
}

.service-title > p {
  margin: 0;
  padding: 0;
}

.service-blur {
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, 1));
}

.expand-button {
  background: #6161d6;
  position: absolute;
  bottom: 10%;
  width: 70%;
  z-index: 2;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  /* box-shadow: 0 9px 12px rgba(0, 0, 0, 0.16), 0 9px 12px rgba(0, 0, 0, 0.23); */
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  /* box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); */
}

.section-title {
  position: relative;
  font-weight: bold;
  font-family: "Source Serif Pro", serif;
  font-size: 50px;
  z-index: 2;
}

.section-paragraph {
  font-family: "Inter", sans-serif !important;
  color: #333;
  font-weight: 500 !important;
  margin-top: 3vh;
  font-weight: 400;
  font-size: 18px;
}

#first-section-paragraph {
  /* width: 10vw; */
  margin-right: 5vw;
}

#first-text,
#second-text {
  position: relative;
  width: 100vw;
  margin-top: 20vh;
}

#first-text {
  padding-top: 10vh;
  /* margin-left: 2.5vw; */
}

#second-text {
  margin-top: 25vh;
}

.dog-collar,
.dog-bone,
.cat-bowl {
  position: absolute;
}

.dog-collar {
  width: 90px;
  height: 90px;
}
@media screen and (max-width: 1921px) {
  .dog-collar {
    width: 120px;
    height: 120px;
  }
}
@media screen and (max-width: 1550px) {
  .dog-collar {
    width: 100px;
    height: 100px;
  }
}
@media screen and (max-width: 1550px) {
  .dog-collar {
    width: 90px;
    height: 90px;
  }
}
@media screen and (max-width: 1153px) {
  .dog-collar {
    width: 80px;
    height: 80px;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  .dog-collar {
    width: 140px;
    height: 140px;
  }
}
@media screen and (max-width: 800px) and (min-height: 1000px) {
  .dog-collar {
    width: 140px;
    height: 140px;
  }
}
@media screen and (max-width: 321px) {
  .dog-collar {
    width: 65px;
    height: 65px;
  }
}

.dog-bone {
  width: 70px;
  height: 70px;
}
@media screen and (max-width: 1921px) {
  .dog-bone {
    width: 95px;
    height: 95px;
  }
}
@media screen and (max-width: 1450px) {
  .dog-bone {
    width: 85px;
    height: 85px;
  }
}
@media screen and (max-width: 1550px) {
  .dog-bone {
    width: 73px;
    height: 73px;
  }
}
@media screen and (max-width: 1153px) {
  .dog-bone {
    width: 66px;
    height: 66px;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  .dog-bone {
    width: 95px;
    height: 95px;
  }
}
@media screen and (max-width: 800px) and (min-height: 1000px) {
  .dog-bone {
    width: 95px;
    height: 95px;
  }
}
@media screen and (max-width: 321px) {
  .dog-bone {
    width: 54px;
    height: 54px;
  }
}

.cat-bowl {
  width: 55px;
  height: 55px;
}
@media screen and (max-width: 1921px) {
  .cat-bowl {
    width: 75px;
    height: 75px;
  }
}
@media screen and (max-width: 1450px) {
  .cat-bowl {
    width: 68px;
    height: 68px;
  }
}
@media screen and (max-width: 1550px) {
  .cat-bowl {
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-width: 1153px) {
  .cat-bowl {
    width: 52px;
    height: 52px;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  .cat-bowl {
    width: 75px;
    height: 75px;
  }
}
@media screen and (max-width: 800px) and (min-height: 1000px) {
  .cat-bowl {
    width: 75px;
    height: 75px;
  }
}
@media screen and (max-width: 321px) {
  .cat-bowl {
    width: 42px;
    height: 42px;
  }
}

.bubble {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
@media screen and (max-width: 1921px) {
  .bubble {
    width: 50px;
    height: 50px;
  }
}

.purple-blue {
  background: #6161d6;
}

.purple {
  background: #b64ff1;
}

.blue {
  background: #d5ffdd;
}

.pink {
  background: #fcabcb;
}

.light-pink {
  background: #f7b5b5;
  opacity: 0.6;
}

#first-collar {
  top: 0px;
  left: 45px;
  transform: rotateZ(30deg);
}

#first-bone {
  top: 80px;
  left: 180px;
  transform: rotateZ(-30deg);
}

#first-bowl {
  top: 180px;
  left: 60px;
  transform: rotateZ(30deg);
}

#first-bubble1 {
  left: -20px;
}

#first-bubble2 {
  right: 135px;
  top: 225px;
}

#first-bubble3 {
  left: 190px;
  top: 450px;
}

#second-bubble1 {
  top: -50px;
  left: 150px;
}

#second-bubble2 {
  bottom: 120px;
  left: 50px;
}

#second-bubble3 {
  bottom: 20px;
  right: 250px;
}

#second-bubble4 {
  top: -130px;
  right: 350px;
}

#faq-bubble1 {
  left: 370px;
  top: 200px;
}

#faq-bubble2 {
  right: 310px;
  top: 405px;
}

#faq-bubble3 {
  right: 80px;
  top: 630px;
}

#faq-bubble4 {
  right: 250px;
  top: 770px;
}

#faq-bubble5 {
  left: 120px;
  bottom: 130px;
}

#second-collar {
  top: -130px;
  right: 85px;
  transform: rotateZ(-30deg);
}

#second-bone {
  top: -50px;
  right: 230px;
  transform: rotateZ(30deg);
}

#second-bowl {
  height: 62.5px;
  width: 62.5px;
  top: 40px;
  right: 65px;
  transform: rotateZ(-30deg);
}

#service-collar1 {
  top: 70vh;
  transform: rotateZ(30deg);
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #service-collar1 {
    top: 45vh;
    left: -4vw;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1300px) {
  #service-collar1 {
    top: 45vh;
    left: -5vw;
  }
}
@media screen and (max-width: 811px) and (min-height: 1000px) {
  #service-collar1 {
    top: 50vh;
    left: -4vw;
  }
}
@media screen and (max-width: 600px) {
  #service-collar1 {
    top: 60vh;
  }
}
@media screen and (max-width: 600px) and (min-height: 650px) {
  #service-collar1 {
    top: 57vh;
  }
}
@media screen and (max-width: 600px) and (min-height: 750px) {
  #service-collar1 {
    top: 52vh;
  }
}
@media screen and (max-width: 600px) and (min-height: 800px) {
  #service-collar1 {
    top: 50vh;
  }
}
@media screen and (min-width: 550px) and (max-width: 602px) and (min-height: 950px) {
  #service-collar1 {
    top: 52vh;
    left: -1.5vh;
  }
}
@media screen and (max-width: 330px) {
  #service-collar1 {
    left: -3vw;
  }
}
@media screen and (max-width: 300px) {
  #service-collar1 {
    left: -6vw;
  }
}

#service-collar2 {
  top: 40vh;
  right: 9vw;
  transform: rotateZ(-40deg);
}
@media screen and (max-width: 1700px) {
  #service-collar2 {
    right: 8vw;
  }
}
@media screen and (max-width: 1450px) {
  #service-collar2 {
    right: 7vw;
  }
}
@media screen and (max-width: 1153px) {
  #service-collar2 {
    right: 6vw;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #service-collar2 {
    top: 120vh;
    right: 45vw;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1300px) {
  #service-collar2 {
    top: 110vh;
    right: 45vw;
  }
}
@media screen and (max-width: 811px) and (min-height: 1000px) {
  #service-collar2 {
    top: 120vh;
    right: 45vw;
  }
}
@media screen and (max-width: 600px) {
  #service-collar2 {
    top: 210vh;
    right: 4vw;
  }
}
@media screen and (max-width: 600px) and (min-height: 650px) {
  #service-collar2 {
    top: 185vh;
    right: 4vw;
  }
}
@media screen and (max-width: 600px) and (min-height: 750px) {
  #service-collar2 {
    top: 168vh;
  }
}
@media screen and (max-width: 600px) and (min-height: 800px) {
  #service-collar2 {
    top: 155vh;
  }
}
@media screen and (min-width: 550px) and (max-width: 602px) and (min-height: 950px) {
  #service-collar2 {
    top: 120vh;
    right: 45vw;
  }
}
@media screen and (max-width: 330px) {
  #service-collar2 {
    right: -2vw;
  }
}
@media screen and (max-width: 330px) {
  #service-collar2 {
    right: -6vw;
  }
}

#service-collar3 {
  top: 140vh;
  right: 0vw;
  transform: rotateZ(40deg);
  z-index: 2;
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #service-collar3 {
    top: 170vh;
    right: -5vw;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1300px) {
  #service-collar3 {
    top: 160vh;
    right: -5vw;
  }
}
@media screen and (max-width: 811px) and (min-height: 1000px) {
  #service-collar3 {
    top: 185vh;
    right: -5vw;
  }
}
@media screen and (max-width: 600px) {
  #service-collar3 {
    top: 390vh;
    right: 85vw;
    transform: rotate(-30deg);
  }
}
@media screen and (max-width: 600px) and (min-height: 650px) {
  #service-collar3 {
    top: 350vh;
    right: 85vw;
    transform: rotate(-30deg);
  }
}
@media screen and (max-width: 600px) and (min-height: 750px) {
  #service-collar3 {
    top: 315vh;
    right: 85vw;
    transform: rotate(-30deg);
  }
}
@media screen and (max-width: 600px) and (min-height: 800px) {
  #service-collar3 {
    top: 300vh;
    right: 85vw;
    transform: rotate(-30deg);
  }
}
@media screen and (min-width: 550px) and (max-width: 602px) and (min-height: 950px) {
  #service-collar3 {
    top: 175vh;
    right: -5vw;
  }
}

#contact-us-collar1 {
  top: 63vh;
  left: 10vw;
  transform: rotateZ(-30deg);
  z-index: 2;
}
@media screen and (max-width: 1281px) and (max-height: 460px) {
  #contact-us-collar1 {
    top: 75vh;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  /* #service-collar1 {
    top: 45vh;
    left: -4vw;
  } */
}
@media screen and (max-width: 1024px) and (min-height: 1300px) {
  /* #service-collar1 {
    top: 45vh;
    left: -5vw;
  } */
}
@media screen and (max-width: 811px) and (min-height: 1000px) {
  #contact-us-collar1 {
    top: 70vh;
    left: 5vw;
  }
}
@media screen and (max-width: 600px) {
  #contact-us-collar1 {
    top: 30vh;
  }
}
@media screen and (min-width: 550px) and (max-width: 602px) and (min-height: 950px) {
  #contact-us-collar1 {
    top: 34vh;
    left: 25vw;
  }
}
@media screen and (max-width: 381px) {
  #contact-us-collar1 {
    top: 31vh;
  }
}
@media screen and (max-width: 321px) {
  #contact-us-collar1 {
    top: 31vh;
    left: -7vw;
  }
}

#service-bowl1 {
  top: 45vh;
  left: 31vw;
  transform: rotateZ(-30deg);
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #service-bowl1 {
    top: 57vh;
    left: 47vw;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1300px) {
  #service-bowl1 {
    top: 60vh;
    left: 47vw;
  }
}
@media screen and (max-width: 811px) and (min-height: 1000px) {
  #service-bowl1 {
    top: 70vh;
    left: 47vw;
  }
}
@media screen and (max-width: 600px) {
  #service-bowl1 {
    left: 86vw;
    top: 138vh;
  }
}
@media screen and (max-width: 600px) and (min-height: 650px) {
  #service-bowl1 {
    left: 86vw;
    top: 124vh;
  }
}
@media screen and (max-width: 600px) and (min-height: 750px) {
  #service-bowl1 {
    left: 85vw;
    top: 109vh;
  }
}
@media screen and (max-width: 600px) and (min-height: 800px) {
  #service-bowl1 {
    left: 85vw;
    top: 105vh;
  }
}
@media screen and (min-width: 550px) and (max-width: 602px) and (min-height: 950px) {
  #service-bowl1 {
    top: 65vh;
    left: 47vw;
  }
}
@media screen and (max-width: 300px) {
  #service-bowl1 {
    left: 90vw;
  }
}

#service-bowl2 {
  top: 115vh;
  left: 61vw;
  transform: rotateZ(30deg);
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #service-bowl2 {
    top: 145vh;
    left: 10vw;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1300px) {
  #service-bowl2 {
    top: 134vh;
    left: 10vw;
  }
}
@media screen and (max-width: 811px) and (min-height: 1000px) {
  #service-bowl2 {
    top: 156vh;
    left: 10vw;
  }
}
@media screen and (max-width: 600px) {
  #service-bowl2 {
    left: 87.5vw;
    top: 290vh;
  }
}
@media screen and (max-width: 600px) and (min-height: 650px) {
  #service-bowl2 {
    left: 87.5vw;
    top: 280vh;
  }
}
@media screen and (max-width: 600px) and (min-height: 750px) {
  #service-bowl2 {
    left: 87.5vw;
    top: 250vh;
  }
}
@media screen and (max-width: 600px) and (min-height: 800px) {
  #service-bowl2 {
    left: 87.5vw;
    top: 235vh;
  }
}
@media screen and (min-width: 550px) and (max-width: 602px) and (min-height: 950px) {
  #service-bowl2 {
    top: 142vh;
    left: 10vw;
  }
}
@media screen and (max-width: 300px) {
  #service-bowl2 {
    left: 80vw;
  }
}

#contact-us-bowl1 {
  top: 44vh;
  left: 33vw;
  transform: rotateZ(30deg);
}
@media screen and (max-width: 1600px) {
  #contact-us-bowl1 {
    top: 43.5vh;
    left: 35vw;
  }
}
@media screen and (max-width: 1450px) {
  #contact-us-bowl1 {
    top: 43.5vh;
    left: 35vw;
  }
}
@media screen and (max-width: 1367px) {
  #contact-us-bowl1 {
    top: 44vh;
    left: 36vw;
  }
}
@media screen and (max-width: 1281px) and (min-height: 550px) {
  #contact-us-bowl1 {
    top: 43vh;
    left: 38vw;
  }
}
@media screen and (max-width: 1281px) and (max-height: 460px) {
  #contact-us-bowl1 {
    top: 50vh;
    left: 38vw;
  }
}
@media screen and (max-width: 1153px) {
  #contact-us-bowl1 {
    top: 43vh;
    left: 40vw;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #contact-us-bowl1 {
    top: 52vh;
    left: 48vw;
  }
}
@media screen and (max-width: 811px) and (min-height: 1000px) {
  #contact-us-bowl1 {
    top: 51vh;
    left: 43vw;
  }
}
@media screen and (max-width: 600px) {
  #contact-us-bowl1 {
    top: 15vh;
    left: 80vw;
  }
}
@media screen and (min-width: 550px) and (max-width: 602px) and (min-height: 950px) {
  #contact-us-bowl1 {
    top: 18vh;
    left: 75vw;
  }
}
@media screen and (max-width: 381px) {
  #contact-us-bowl1 {
    top: 17vh;
    left: 80vw;
  }
}
@media screen and (max-width: 381px) and (max-height: 670px) {
  #contact-us-bowl1 {
    top: 18vh;
    left: 80vw;
  }
}
@media screen and (max-width: 321px) {
  #contact-us-bowl1 {
    top: 19vh;
    left: 80vw;
  }
}

#service-bone1 {
  top: 78vh;
  left: 58vw;
  transform: rotateZ(-60deg);
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #service-bone1 {
    top: 85vh;
    left: 80vw;
    transform: rotateZ(40deg);
  }
}
@media screen and (max-width: 1024px) and (min-height: 1300px) {
  #service-bone1 {
    top: 76vh;
    left: 80vw;
    transform: rotateZ(40deg);
  }
}
@media screen and (max-width: 811px) and (min-height: 1000px) {
  #service-bone1 {
    top: 93vh;
    left: 80vw;
    transform: rotateZ(40deg);
  }
}
@media screen and (max-width: 600px) {
  #service-bone1 {
    top: 175vh;
    left: -7vw;
  }
}
@media screen and (max-width: 600px) and (min-height: 650px) {
  #service-bone1 {
    top: 160vh;
    left: -7vw;
  }
}
@media screen and (max-width: 600px) and (min-height: 750px) {
  #service-bone1 {
    top: 145vh;
    left: -7vw;
  }
}
@media screen and (max-width: 600px) and (min-height: 800px) {
  #service-bone1 {
    top: 135vh;
    left: -7vw;
  }
}
@media screen and (min-width: 550px) and (max-width: 602px) and (min-height: 950px) {
  #service-bone1 {
    top: 83vh;
    left: 80vw;
    transform: rotateZ(40deg);
  }
}

#service-bone2 {
  top: 150vh;
  left: 27vw;
  transform: rotateZ(-30deg);
}
@media screen and (min-width: 1601px) and (max-width: 1681px) {
  #service-bone2 {
    top: 140vh;
  }
}
@media screen and (max-width: 1367px) {
  #service-bone2 {
    top: 168vh;
  }
}
@media screen and (max-width: 1281px) and (min-height: 800px) {
  #service-bone2 {
    top: 140vh;
  }
}
@media screen and (max-width: 1153px) {
  #service-bone2 {
    top: 155vh;
    left: 28.5vw;
  }
}
@media screen and (max-width: 1400px) and (min-height: 1000px) {
  #service-bone2 {
    top: 125vh;
    /* left: 30vw; */
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #service-bone2 {
    top: 200vh;
    left: 35vw;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1300px) {
  #service-bone2 {
    top: 183vh;
    left: 35vw;
  }
}
@media screen and (max-width: 811px) and (min-height: 1000px) {
  #service-bone2 {
    top: 220vh;
    left: 35vw;
  }
}
@media screen and (max-width: 800px) and (min-height: 1250px) {
  #service-bone2 {
    top: 190vh;
    left: 35vw;
  }
}
@media screen and (max-width: 600px) {
  #service-bone2 {
    top: 265vh;
    left: 5vw;
  }
}
@media screen and (max-width: 600px) and (min-height: 650px) {
  #service-bone2 {
    top: 240vh;
    left: 5vw;
  }
}
@media screen and (max-width: 600px) and (min-height: 750px) {
  #service-bone2 {
    top: 211vh;
    left: 5vw;
  }
}
@media screen and (max-width: 600px) and (min-height: 800px) {
  #service-bone2 {
    top: 197.5vh;
    left: 5vw;
  }
}
@media screen and (min-width: 550px) and (max-width: 602px) and (min-height: 950px) {
  #service-bone2 {
    top: 190vh;
    left: 35vw;
  }
}

#service-bone3 {
  top: 430vh;
  left: 80vw;
  transform: rotateZ(30deg);
}
@media screen and (min-height: 650px) {
  #service-bone3 {
    top: 410vh;
  }
}
@media screen and (min-height: 700px) {
  #service-bone3 {
    top: 395vh;
  }
}
@media screen and (min-height: 750px) {
  #service-bone3 {
    top: 360vh;
  }
}
@media screen and (min-height: 870px) {
  #service-bone3 {
    top: 350vh;
  }
}

#contact-us-bone1 {
  top: 29vh;
  left: 9vw;
  transform: rotateZ(-30deg);
}
@media screen and (max-width: 1600px) {
  #contact-us-bone1 {
    top: 26vh;
    left: 7.5vw;
  }
}
@media screen and (max-width: 1450px) {
  #contact-us-bone1 {
    top: 28vh;
    left: 8vw;
  }
}
@media screen and (max-width: 1367px) {
  #contact-us-bone1 {
    top: 26vh;
    left: 8.5vw;
  }
}
@media screen and (max-width: 1025px) {
  #contact-us-bone1 {
    top: 21vh;
    left: 7vw;
  }
}
@media screen and (max-width: 811px) and (min-height: 1000px) {
  #contact-us-bone1 {
    top: 17vh;
    left: 0vw;
  }
}
@media screen and (max-width: 600px) {
  #contact-us-bone1 {
    top: 3vh;
    left: 4vw;
  }
}
@media screen and (min-width: 550px) and (max-width: 602px) and (min-height: 950px) {
  #contact-us-bone1 {
    top: 1vh;
    left: 4vw;
  }
}
@media screen and (max-width: 384px) {
  #contact-us-bone1 {
    top: 0vh;
    left: 4vw;
  }
}
@media screen and (max-width: 381px) and (max-height: 670px) {
  #contact-us-bone1 {
    top: -4vh;
    left: 5vw;
  }
}
@media screen and (max-width: 321px) {
  #contact-us-bone1 {
    top: -6vh;
    left: 3vw;
  }
}

#service-background-bubble1 {
  right: 2vw;
  top: 140vh;
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #service-background-bubble1 {
    top: 173vh;
    right: -2vw;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1300px) {
  #service-background-bubble1 {
    top: 163vh;
    right: -2vw;
  }
}
@media screen and (max-width: 811px) and (min-height: 1000px) {
  #service-background-bubble1 {
    top: 188vh;
    right: -2vw;
  }
}
@media screen and (max-width: 600px) {
  #service-background-bubble1 {
    top: 390vh;
    right: 93vw;
  }
}
@media screen and (max-width: 600px) and (min-height: 650px) {
  #service-background-bubble1 {
    top: 350vh;
    right: 93vw;
  }
}
@media screen and (max-width: 600px) and (min-height: 750px) {
  #service-background-bubble1 {
    top: 315vh;
    right: 93vw;
  }
}
@media screen and (max-width: 600px) and (min-height: 800px) {
  #service-background-bubble1 {
    top: 300vh;
    right: 93vw;
  }
}
@media screen and (min-width: 550px) and (max-width: 602px) and (min-height: 950px) {
  #service-background-bubble1 {
    top: 175vh;
    right: -2vw;
  }
}

#faq-collar {
  top: 370px;
  left: 125px;
  transform: rotateZ(-30deg);
}

#faq-bone {
  top: 450px;
  left: -30px;
  transform: rotateZ(30deg);
}

#faq-bowl {
  top: 540px;
  left: 230px;
  transform: rotateZ(-30deg);
}

.image-and-text {
  position: relative;
  display: flex;
  width: 85vw;
  margin-left: 7.5vw;
}

#first-image-and-text {
  margin-left: 10vw;
}

#second-image-and-text {
  margin-left: 7.5vw;
  width: 83vw;
}

#blue-image-container,
#pink-image-container {
  position: relative;
}

#blue-dogos {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(55%, -50%);
  height: 21vh;
}

#blue-circle,
#pink-circle {
  height: 65vh;
}

#blue-text-container {
  margin: 70px 0 0 7.5vw;
}

#pink-dogo {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(60%, -55%);
  height: 32vh;
}

#pink-text-container {
  margin: 80px 5vw 0 7.5vw;
}

#faq {
  position: relative;
  padding-top: 20vh;
  width: 100vw;
}

#faq-section-title {
  width: 100vw;
  font-family: "Source Serif Pro", serif;
  text-align: center;
  margin: 15vh 0 15vh 0;
  font-size: 45px;
  font-weight: 900;
  color: #333;
}
@media screen and (min-width: 1800px) {
  #faq-section-title {
    font-size: 42px;
  }
}
@media screen and (max-width: 800px) {
  #faq-section-title {
    width: 80vw;
    margin-left: 10vw;
    font-size: 28px;
  }
}

#contact-us {
  position: relative;
  display: flex;
  height: 90vh;
  min-height: 500px;
  width: 100vw;
}
@media screen and (max-width: 700px) {
  #contact-us {
    height: 120vh;
    flex-direction: column;
    min-height: 700px;
  }
}
@media screen and (max-width: 320px) {
  #contact-us {
    height: 120vh;
    flex-direction: column;
  }
}

#contact-us-text {
  height: 100%;
  width: 50vw;
  display: flex;
  align-items: center;
  margin: 0;
}
@media screen and (max-width: 700px) {
  #contact-us-text {
    height: 30%;
    justify-content: center;
    width: 100vw;
  }
}

#contact-us-text > h4 {
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0;
  font-size: 32px;
  font-weight: 900;
  color: #38424d;
  line-height: 50px;
  width: 33vw;
  margin-left: 12vw;
}
@media screen and (min-width: 1800px) {
  #contact-us-text > h4 {
    font-size: 40px;
    line-height: 65px;
    margin-left: 12vw;
  }
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #contact-us-text > h4 {
    width: 37vw;
    font-size: 55px;
    line-height: 70px;
    margin-left: 8vw;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #contact-us-text > h4 {
    width: 33vw;
    font-size: 50px;
    line-height: 70px;
    margin-left: 8vw;
  }
}
@media screen and (max-width: 800px) and (min-height: 900px) {
  #contact-us-text > h4 {
    width: 33vw;
    font-size: 42px;
    line-height: 70px;
    margin-left: 8vw;
  }
}
@media screen and (max-width: 700px) {
  #contact-us-text > h4 {
    width: 80vw;
    font-size: 28px;
  }
}
@media screen and (max-width: 320px) {
  #contact-us-text > h4 {
    font-size: 24px;
  }
}

#contact-us-form {
  height: 100%;
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: #38424d;
}
@media screen and (max-width: 700px) {
  #contact-us-form {
    height: 70%;
    width: 100vw;
  }
}

#form-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 40%;
  min-height: 420px;
  height: 75%;
  background: snow;
  /* background: rgb(220, 238, 250);
  background: white; */
  border: 4px solid #fd80b2;
  border: 4px solid #f7b5b5;
  /* background: #fd80b2; */
  /* background: #F7B5B5; */
  border-radius: 24px;
  margin-right: 10vw;
  /* box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #form-container {
    width: 70%;
    height: 70%;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #form-container {
    width: 60%;
    height: 70%;
  }
}
@media screen and (max-width: 800px) and (min-height: 900px) {
  #form-container {
    width: 80%;
    height: 70%;
  }
}
@media screen and (min-width: 900px) and (max-width: 965px) and (max-height: 602px) {
  #form-container {
    height: 80%;
  }
}
@media screen and (max-width: 720px) {
  #form-container {
    width: 70%;
    max-width: 350px;
    /* min-width: 400px; */
    height: clamp(450px, 80%, 567px);
    /* min-height: 450px; */
    margin-right: 0;
  }
}

.input-container {
  width: 80%;
  z-index: 2;
}

input {
  min-height: 30px;
  outline: none;
  padding: 5px 12px;
  border-radius: 16px;
  -webkit-appearance: none !important;
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  input {
    min-height: 40px;
    font-size: 20px;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  input {
    min-height: 35px;
    font-size: 18px;
  }
}
@media screen and (min-width: 1800px) {
  input {
    min-height: 35px;
  }
}
@media screen and (max-width: 800px) {
  input {
    font-size: 12px;
  }
}
@media screen and (min-width: 900px) and (max-width: 965px) and (max-height: 602px) {
  input {
    min-height: 25px;
    font-size: 12px;
  }
}

#text-area-container {
  height: 20%;
}

.input-container > h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #38424d;
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  .input-container > h4 {
    font-size: 26px;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  .input-container > h4 {
    font-size: 21px;
    margin-bottom: 8px;
  }
}
@media screen and (min-width: 900px) and (max-width: 965px) and (max-height: 602px) {
  .input-container > h4 {
    font-size: 14px;
    margin-bottom: 6px;
  }
}

#name {
  width: 100%;
  background: #f7f7f7;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
}

#email {
  width: 100%;
  background: #f7f7f7;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
}

#phone-input {
  width: 100%;
  background: #f7f7f7;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
}

#message {
  width: 100%;
  height: 70%;
  background: #f7f7f7;
  resize: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 16px;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
  -webkit-appearance: none !important;
}
@media screen and (max-width: 1024px) and (min-height: 1100px) {
  #message {
    font-size: 20px;
  }
}
@media screen and (max-width: 835px) and (min-height: 940px) {
  #message {
    font-size: 18px;
  }
}
@media screen and (max-width: 700px) {
  #message {
    font-size: 12px;
  }
}
@media screen and (min-width: 900px) and (max-width: 965px) and (max-height: 602px) {
  #message {
    font-size: 12px;
  }
}

#send-button {
  background: #6161d6;
  color: white;
  border-radius: 40px;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  width: 80%;
  z-index: 2;
}
@media only screen and (min-width: 1800px) {
  #send-button {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1024px) and (min-height: 1100px) {
  #send-button {
    font-size: 24px;
  }
}
@media only screen and (max-width: 835px) and (min-height: 940px) {
  #send-button {
    font-size: 20px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 965px) and (max-height: 602px) {
  #send-button {
    font-size: 12px;
  }
}

#contact-form-fluter-phone {
  margin-bottom: 0;
  font-size: 14px;
  text-decoration: none !important;
}
@media screen and (max-width: 360px) {
  #contact-form-fluter-phone {
    font-size: 13px;
  }
}
@media screen and (max-width: 320px) {
  #contact-form-fluter-phone {
    font-size: 11px;
  }
}
@media screen and (max-width: 290px) {
  #contact-form-fluter-phone {
    font-size: 10px;
  }
}

.error {
  color: #f30;
  font-size: 1rem;
  width: 80%;
  text-align: center;
  font-weight: 600;
}
@media screen and (max-width: 800px) {
  .error {
    font-size: 0.8rem;
  }
}

.success {
  color: #5cb85c;
  font-size: 1rem;
  width: 80%;
  text-align: center;
  font-weight: 600;
}
@media screen and (max-width: 1025px) and (max-height: 601px) {
  .success {
    margin-top: 10px;
    font-size: 0.9rem !important;
    /* background: blue; */
  }
}
@media screen and (max-width: 700px) {
  .success {
    font-size: 0.8rem;
  }
}

.shape {
  position: absolute;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.23), 2px 2px 4px rgba(0, 0, 0, 0.36);
}

/* FCABCB
F7B5B5
D5FFDD
B64FF1
6161D6 */

.shape1 {
  width: 75px;
  height: 75px;
  /* background: -webkit-linear-gradient(
    rgba(254, 132, 100, 0.5) 0%,
    rgba(254, 110, 154, 0.5) 100%
  );
  background: -o-linear-gradient(
    rgba(254, 132, 100, 0.5) 0%,
    rgba(254, 110, 154, 0.5) 100%
  );
  background: linear-gradient(
    rgba(254, 132, 100, 0.5) 0%,
    rgba(254, 110, 154, 0.5) 100%
  ); */
  background: #f7b5b5;
  border-radius: 50%;
  left: 130px;
  top: 25%;
}
@media only screen and (max-width: 800px) {
  .shape1 {
    top: 15%;
    left: 10px;
  }
}
.shape2 {
  width: 39px;
  height: 39px;
  /* background: -webkit-linear-gradient(
    rgba(51, 200, 193, 0.5) 0%,
    rgba(17, 155, 210, 0.5) 100%
  );
  background: -o-linear-gradient(
    rgba(51, 200, 193, 0.5) 0%,
    rgba(17, 155, 210, 0.5) 100%
  );
  background: linear-gradient(
    rgba(51, 200, 193, 0.5) 0%,
    rgba(17, 155, 210, 0.5) 100%
  ); */
  background: #b64ff1;
  left: 150px;
  bottom: 40px;
  border-radius: 50%;
}
@media only screen and (max-width: 700px) {
  .shape2 {
    bottom: 35%;
    left: 60px;
  }
}
.shape3 {
  width: 19px;
  height: 19px;
  /* background: -webkit-linear-gradient(
    rgba(54, 28, 193, 0.5) 0%,
    rgba(46, 130, 239, 0.5) 100%
  );
  background: -o-linear-gradient(
    rgba(54, 28, 193, 0.5) 0%,
    rgba(46, 130, 239, 0.5) 100%
  );
  background: linear-gradient(
    rgba(54, 28, 193, 0.5) 0%,
    rgba(46, 130, 239, 0.5) 100%
  ); */
  background: #6161d6;
  bottom: 25%;
  left: 26%;
  border-radius: 50%;
}
@media only screen and (max-width: 700px) {
  .shape3 {
    bottom: 50%;
    left: 65%;
  }
}
.shape4 {
  /* background-color: rgba(226, 158, 25, 0.55); */
  background-color: #d5ffdd;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  top: 175px;
  left: 40%;
}
@media only screen and (max-width: 700px) {
  .shape4 {
    top: 250px;
    left: 65%;
  }
}
.shape5 {
  width: 19px;
  height: 19px;
  /* background-color: rgba(108, 99, 255, 0.55); */
  background-color: #fcabcb;
  transform: scale(1, 1);
  left: 50%;
  bottom: 20%;
  border-radius: 50%;
}
@media only screen and (max-width: 700px) {
  .shape5 {
    left: 75%;
  }
}
.shape6 {
  width: 14px;
  height: 14px;
  background-color: rgba(235, 163, 26, 0.55);
  border-radius: 50%;
  left: 45%;
  bottom: 70px;
}
@media only screen and (max-width: 700px) {
  .shape6 {
    bottom: 22%;
    left: 5%;
  }
}

.formShape1 {
  top: -10px;
  left: -20px;
}
.formShape2 {
  top: 20%;
  left: unset;
  right: 15%;
}
.formShape3 {
  top: 35%;
}
.formShape4 {
  top: 55%;
  left: unset;
  right: 1%;
}
.formShape5 {
  top: 65%;
  left: 5%;
  height: 30px;
  width: 30px;
}
.formShape6 {
  top: 92%;
  left: 55%;
  height: 30px;
  width: 30px;
}

#footer {
  position: relative;
  width: 100vw;
  min-height: 300px;
  display: flex;
  background: #6161d6;
  /* background: aliceblue; */
  /* background: #CDB4DB; */
}
@media screen and (max-width: 800px) {
  #footer {
    flex-direction: column;
  }
}

#footer-logo-container {
  width: 40%;
  width: 40%;
  height: 100%;
  margin: 2% 0 50px 0;
  display: flex;
  flex-direction: column;
  z-index: 2;

  /* temp */
  /* margin-top: 7.3%; */
}
@media screen and (max-width: 800px) {
  #footer-logo-container {
    width: 100%;
    height: unset;
    margin: 50px 0 0 0;
  }
}

#footer-logo-container > h4 {
  padding-left: 10vw;
}

#footer-logo {
  width: 100%;
  height: 100%;
  transform: translateX(-1%);
}

#footer-logo > img {
  width: 40%;
  height: auto;
}

#footer-logo {
  font-family: "Source Serif Pro", serif;
  letter-spacing: 1px;
  font-size: 33px;
  font-weight: 900;
  color: #333;
  color: #6161d6;
  text-shadow: -1px 1px -1px rgba(0, 0, 0, 0.21);
}

#footer-description {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #747e88;
  color: #e0e0e0;
}
@media screen and (max-width: 800px) {
  #footer-description {
    font-size: 15px;
    margin-right: 10px;
  }
}

#footer-links {
  height: 100%;
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-top: 35px;
  margin: 9% 0 50px 0;
  z-index: 2;
}
@media screen and (max-width: 800px) {
  #footer-links {
    width: 100%;
    flex-direction: column;
    padding-top: 0px;
    margin-left: 10vw;
  }
}

#links {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* margin-left: 150px; */
}

#useful-links {
  /* margin-left: 150px; */
  /* margin-left: 13px; */
  margin-bottom: 25px;
  font-weight: 600;
  color: #38424d;
  color: #6161d6;
  color: #fff;
}
@media screen and (max-width: 800px) {
  #useful-links {
    font-size: 20px;
  }
}

#link-column1 {
  margin-right: 50px;
}

#link-column1 > *,
#link-column2 > * {
  margin-bottom: 17px;
  color: #747e88;
  color: #e0e0e0;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  #link-column1 > *,
  #link-column2 > * {
    font-size: 16px;
  }

  #link-column1,
  #link-column2 {
    margin-bottom: 25px;
  }
}

/* #link-column3 > * {
  cursor: unset;
} */

#instagram-hyperlink,
#phone-hyperlink,
#mail-hyperlink {
  display: flex;
  align-items: flex-start;
  margin-bottom: 17px;
}

#phone,
#contact-email,
/* #street, */
#footer-instagram {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  /* background: #fff; */
}

#footer-phone-number,
#footer-email-text,
/* #street > h4, */
#footer-instagram-text {
  margin: 0;
  padding: 0;
  color: #747e88;
  color: #e0e0e0;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}
#footer-phone-number {
  margin-left: 5px;
}
@media screen and (max-width: 800px) {
  #footer-phone-number,
  #footer-email-text,
  #street > h4 {
    font-size: 15px;
  }
}

#footer-phone-number:hover,
#footer-email-text:hover,
/* #street > h4:hover, */
#footer-instagram-text:hover {
  color: #8888f7 !important;
  color: #fff !important;
  font-weight: 600;
  text-decoration: underline;
}

.footer-icon {
  /* margin-top: 5px; */
  margin-right: 10px;
  color: #6161d6;
  color: #fff;
}

#instagram-icon {
  margin-top: 7.5px;
}
@media screen and (max-width: 800px) {
  #instagram-icon {
    margin-top: 8px;
  }
}

#phone-icon {
  margin-top: 5px;
}
@media screen and (max-width: 800px) {
  #phone-icon {
    margin-top: 3px;
  }
}

#mail-icon {
  margin-top: 7px;
}
@media screen and (max-width: 800px) {
  #mail-icon {
    margin-top: 5px;
  }
}

#link-column1 > *:hover,
#link-column2 > *:hover {
  color: #5cb85c;
  color: #8888f7;
}

/* #link-column2 {
  margin-left: 150px;
} */

.footer-shape {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.23), 2px 2px 4px rgba(0, 0, 0, 0.36);
}

.footer-shape.shape-1 {
  position: absolute;
  background: -webkit-linear-gradient(#fe8464 0%, #fe6e9a 100%);
  background: -o-linear-gradient(#fe8464 0%, #fe6e9a 100%);
  background: linear-gradient(#fe8464 0%, #fe6e9a 100%);
  width: 75px;
  height: 75px;
  border-radius: 50%;
  left: 70px;
  opacity: 0.55;
}
@media (max-width: 767px) {
  .footer-shape.shape-1 {
    left: auto;
    right: 40px;
    top: 25%;
  }
}
.footer-shape.shape-2 {
  position: absolute;
  background: -webkit-linear-gradient(#33c8c1 0%, #119bd2 100%);
  background: -o-linear-gradient(#33c8c1 0%, #119bd2 100%);
  background: linear-gradient(#33c8c1 0%, #119bd2 100%);
  width: 39px;
  height: 39px;
  border-radius: 50%;
  top: unset;
  right: unset;
  bottom: 40px !important;
  left: 15% !important;
  opacity: 0.55;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-shape.shape-2 {
    bottom: 85px;
  }
}
@media (max-width: 767px) {
  .footer-shape.shape-2 {
    bottom: 110px;
  }
}
.footer-shape.shape-3 {
  position: absolute;
  background: -webkit-linear-gradient(#361cc1 0%, #2e82ef 100%);
  background: -o-linear-gradient(#361cc1 0%, #2e82ef 100%);
  background: linear-gradient(#361cc1 0%, #2e82ef 100%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 35%;
  top: 40px;
  opacity: 0.55;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-shape.shape-3 {
    top: 40%;
  }
}
.footer-shape.shape-4 {
  position: absolute;
  background-color: #eba31a;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: 50%;
  bottom: 5px;
  opacity: 0.55;
}
.footer-shape.shape-5 {
  position: absolute;
  background-color: #e29e19;
  right: 37%;
  top: 0;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  opacity: 0.55;
}
.footer-shape.shape-6 {
  position: absolute;
  background-color: #b60d81;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 125px;
  right: 15%;
  opacity: 0.55;
}
.footer-shape.shape-7 {
  position: absolute;
  background-color: #6c63ff;
  width: 64px;
  height: 64px;
  bottom: 25px;
  right: 8%;
  opacity: 0.55;
  border-radius: 50%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-shape.shape-7 {
    bottom: 20%;
  }
}
@media (max-width: 767px) {
  .footer-shape.shape-7 {
    bottom: 38%;
  }
}

@media (max-width: 800px) {
  #start-screen {
    flex-direction: column;
  }

  #copyright,
  #image {
    width: 100%;
    height: unset;
  }
}
</style>